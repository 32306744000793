<template>
  <PageLayout>
    <template v-slot:header>
      <PageHeader title="Overview" :breadcrumbs="breadcrumbs" />
    </template>
    <template v-slot:body>
      <!-- top social card -->
      <v-row class="mt-0">
        <v-col class="my-3">
          <v-card flat class="rounded-lg" height="300" outlined>
            <v-img
              height="250px"
              gradient="to top right, rgba(49, 27, 146, .33), rgba(49, 27, 146, .9)"
              src="../assets/city.svg"
            >
              <v-container v-if="screenIsMobile" fill-height>
                <v-row no-gutters>
                  <v-col align="center">
                    <v-avatar size="85" style="border: 2px solid white">
                      <v-img :src="userPhoto"></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="12" align="center">
                    <h5 class="text-h5 font-weight-bold" style="color: white">
                      {{ username }}
                    </h5>
                  </v-col>
                  <v-col cols="12" align="center">
                    <p class="text-subtitle-2" style="color: white; opacity: 0.5">User</p>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
            <v-list-item
              v-if="!screenIsMobile"
              style="position: absolute; top: 145px; left: 15px; color: white"
            >
              <v-list-item-avatar size="125" style="border: 2px solid white">
                <v-img :src="userPhoto"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-h5 font-weight-bold">{{
                  username
                }}</v-list-item-title>
                <v-list-item-subtitle
                  class="text-subtitle-2"
                  style="color: white; opacity: 0.5"
                >
                  User
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>

      <!-- body section -->
      <v-row class="mt-0">
        <v-col cols="12" md="4">
          <v-card outlined class="rounded-lg mb-5">
            <v-card-title class="text-h6 font-weight-bold">About</v-card-title>
            <v-btn absolute top right icon @click="setEditAbout">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-card-text>
              <v-list two-line subheader>
                <v-list-item>
                  <v-list-item-avatar color="black">
                    <v-icon dark>mdi-account</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Biography</v-list-item-title>
                    <v-list-item-subtitle class="text-wrap" v-if="!isEditAbout">
                      {{ userBio }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      <v-textarea outlined v-model="editBio"></v-textarea>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-list-item>
                  <v-list-item-avatar color="black">
                    <v-icon dark>mdi-trophy</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Vision</v-list-item-title>
                    <v-list-item-subtitle class="text-wrap" v-if="!isEditAbout">
                      {{ userVision }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      <v-textarea outlined v-model="editVision"></v-textarea>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions v-if="isEditAbout">
              <v-spacer></v-spacer>
              <v-btn color="error" @click="resetEditAbout()">Cancel</v-btn>
              <v-btn color="primary" @click="updateUser()">Save Changes</v-btn>
            </v-card-actions>
          </v-card>
          <v-card outlined class="rounded-lg mb-5">
            <v-card-title class="text-h6 font-weight-bold">Motivations</v-card-title>
            <v-card-text>
              <v-chip-group column v-if="motivators.length > 0">
                <v-chip
                  v-for="motivator in motivators"
                  :key="motivator.id"
                  :color="motivator.color"
                  dark
                  :to="`/goals/${motivator.goalId}`"
                >
                  {{ motivator.name }}
                </v-chip>
              </v-chip-group>
              <p v-else>No motivations added yet.</p>
            </v-card-text>
          </v-card>
          <v-card flat outlined class="rounded-lg">
            <v-card-title class="font-weight-bold">Upcoming Events</v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="upcomingEvents.length > 0" class="pa-0">
              <v-timeline align-top dense>
                <v-timeline-item
                  v-for="(event, i) in upcomingEvents"
                  :key="i"
                  :color="getEventColor(event)"
                  small
                  fill-dot
                >
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <p>
                        {{ formatEventTimes(event.start, event.end) }}
                      </p>
                    </v-col>
                    <v-col>
                      <strong>{{ event.name }}</strong>
                      <div class="text-caption">
                        {{ event.description }}
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
            <v-card-text v-else>
              <p>No upcoming events today.</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer />
              <v-btn text to="/calendar" color="primary">
                Calendar View
                <v-icon right>mdi-chevron-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="8">
          <v-row>
            <v-col cols="12">
              <v-card flat outlined class="rounded-lg">
                <v-card-title class="font-weight-bold">Active Goals</v-card-title>
                <v-btn absolute top right dark @click="newGoalDialog = !newGoalDialog">
                  <v-icon>mdi-plus</v-icon>
                  add goal
                </v-btn>

                <v-navigation-drawer
                  v-model="newGoalDialog"
                  right
                  app
                  temporary
                  width="420"
                >
                  <v-card flat tile>
                    <v-toolbar flat color="grey lighten-3">
                      <v-toolbar-title>New Goal</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="newGoalDialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-row>
                              <v-col offset="1">
                                <v-text-field
                                  label="Goal Title"
                                  v-model="newGoal.name"
                                  required
                                />
                              </v-col>
                            </v-row>
                            <v-row align="center">
                              <v-col cols="1">
                                <v-icon class="mb-1">mdi-text</v-icon>
                              </v-col>
                              <v-col>
                                <v-text-field
                                  label="Description"
                                  v-model="newGoal.description"
                                  required
                                />
                              </v-col>
                            </v-row>
                            <v-row align="center">
                              <v-col cols="1">
                                <v-icon class="mb-1">mdi-view-dashboard</v-icon>
                              </v-col>
                              <v-col>
                                <v-chip-group
                                  v-model="newGoal.spaceId"
                                  active-class="grey darken-2 white--text"
                                  column
                                  mandatory
                                >
                                  <v-chip
                                    v-for="space in $store.state.space.spaces"
                                    :color="space.color"
                                    dark
                                    :key="space.id"
                                    :value="space.id"
                                  >
                                    <v-icon left>{{ space.icon }}</v-icon>
                                    {{ space.name }}
                                  </v-chip>
                                </v-chip-group>
                              </v-col>
                              <v-btn right dark to="/my-spaces"> Add a New Space! </v-btn>
                            </v-row>
                          </v-col>
                          <v-col cols="12" align="center">
                            <v-card max-width="400" flat outlined class="rounded-xl">
                              <v-card-text>
                                <v-date-picker
                                  v-model="newGoal.completionDate"
                                  color="primary"
                                  no-title
                                />
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-alert
                            v-if="errorMsg"
                            border="top"
                            color="red"
                            class="ml-5 mr-5"
                          >
                            {{ errorMsg }}
                          </v-alert>
                        </v-row>
                        <v-card-actions>
                          <v-btn color="primary" @click="addNewGoal" :disabled="!isValid">
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-navigation-drawer>

                <v-card-text>
                  <v-row v-if="activeGoals.length > 0">
                    <v-col
                      v-for="goal in activeGoals"
                      :key="goal.id"
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <v-hover v-slot="{ hover }">
                        <v-card
                          outlined
                          class="rounded-lg"
                          :elevation="hover ? 1 : 0"
                          :to="`goals/${goal.id}`"
                        >
                          <v-row align="center">
                            <v-col cols="12" align="center" class="mt-4">
                              <v-progress-circular
                                :color="getSpace(goal).color"
                                :value="getGoalProgress(goal)"
                                size="100"
                                width="8"
                              >
                                {{ getGoalProgress(goal) }}%
                              </v-progress-circular>
                            </v-col>
                          </v-row>
                          <v-row class="my-4">
                            <v-col align="center">
                              <div class="text-h6 font-weight-bold">
                                {{ goal.name }}
                              </div>
                              <div class="text-subtitle-2 grey--text">
                                {{ goal.description }}
                              </div>
                            </v-col>
                          </v-row>
                          <v-divider></v-divider>
                          <v-row class="my-1">
                            <v-col align="center">
                              <v-chip dark :color="getSpace(goal).color">{{
                                goal.numCompleted
                              }}</v-chip>
                              <p>completed</p>
                            </v-col>
                            <v-col align="center">
                              <v-chip dark :color="getSpace(goal).color">{{
                                goal.numToComplete
                              }}</v-chip>
                              <p>to complete</p>
                            </v-col>
                            <v-col align="center">
                              <v-chip dark :color="getSpace(goal).color">{{
                                goal.completionDate
                                  ? formattedDueDate(goal.completionDate)
                                  : "None"
                              }}</v-chip>
                              <p>due date</p>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-hover>
                    </v-col>
                  </v-row>
                  <p v-else>No active goals. Click 'Add Goal' to create one!</p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card flat outlined class="rounded-lg">
                <v-card-title class="font-weight-bold">Tasks</v-card-title>
                <v-card-subtitle>{{ date }}</v-card-subtitle>
                <v-card-text>
                  <v-slide-y-transition
                    v-if="todaysTasks.length > 0"
                    class="py-0"
                    group
                    tag="v-list"
                  >
                    <template>
                      <v-list-item v-for="(task, i) in todaysTasks" :key="i">
                        <v-list-item-action>
                          <v-checkbox v-model="task.isComplete">
                            <template v-slot:label>
                              <div
                                :class="
                                  (task.isComplete && 'text-decoration-line-through') ||
                                  'black--text'
                                "
                                class="ml-4"
                                v-text="task.name"
                              ></div>
                            </template>
                          </v-checkbox>
                        </v-list-item-action>

                        <v-spacer></v-spacer>
                      </v-list-item>
                    </template>
                  </v-slide-y-transition>
                  <p v-else>No tasks to complete today.</p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn text color="primary" to="/tasks">
                    Tasks View
                    <v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-snackbar v-model="savingSnackbar">
        Saving...

        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="savingSnackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="successSnackbar">
        Goal saved

        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="successSnackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="errorSnackbar">
        Error encountered while saving goal

        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="errorSnackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </template>
  </PageLayout>
</template>

<script>
import { db } from "../firebase/firebase";
import PageLayout from "../layouts/PageLayout";
import PageHeader from "../components/PageHeader";
import { mapState, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "Overview",
  components: {
    PageLayout,
    PageHeader,
  },
  data() {
    return {
      //breadcrumbs
      breadcrumbs: [
        {
          icon: "mdi-home",
          text: "",
          href: "/",
        },
        {
          text: "Overview"
        }
      ],

      //about variables
      editVision: "",
      editBio: "",
      isEditAbout: false,

      //dialogs
      savingSnackbar: false,
      successSnackbar: false,
      errorSnackbar: false,
      newGoalDialog: false,
      newGoal: {
        name: "",
        spaceId: "",
        completionDate: "",
        description: "",
      },
      errorMsg: "",
    };
  },
  computed: {
    activeGoals() {
      return this.$store.state.goal.goals.filter((goal) => !goal.isComplete);
    },
    isValid() {
      return this.newGoal.name.length > 0 && this.newGoal.spaceId.length > 0;
    },
    screenIsMobile() {
      return this.$vuetify.breakpoint.width < 960;
    },
    todaysTasks() {
      let today = moment(Date.now()).format("yyyy-MM-DD");
      return this.$store.state.task.tasks.filter((task) => task.date === today);
    },
    upcomingEvents() {
      let today = moment(Date.now()).format("yyyy-MM-DD");
      return this.$store.state.event.events.filter(
        (event) => moment(event.start).format("yyyy-MM-DD") === today
      );
    },
    date() {
      return moment(new Date()).format("ddd, MMMM Do, YYYY");
    },
    getNewGoalColor() {
      if (this.newGoal.spaceId === "none") {
        return "grey";
      } else {
        return this.newGoal.spaceId
          ? this.$store.state.space.spaces.find(
              (space) => space.id === this.newGoal.spaceId
            ).color
          : "black";
      }
    },
    spaceDropdown() {
      return [...this.$store.state.space.spaces, { name: "None", id: "none" }];
    },
    ...mapState({
      username: (state) => state.user.name,
      userId: (state) => state.user.id,
      userPhoto: (state) => state.user.photoURL,
      userEmail: (state) => state.user.email,
      userVision: (state) => state.user.vision,
      userBio: (state) => state.user.bio,
    }),
    ...mapGetters({
      motivators: "motivator/motivatorsWithColor",
    }),
  },
  methods: {
    // editing about me
    setEditAbout() {
      this.editVision = this.userVision;
      this.editBio = this.userBio;
      this.isEditAbout = true;
    },
    resetEditAbout() {
      this.editVision = "";
      this.editBio = "";
      this.isEditAbout = false;
    },
    async updateUser() {
      try {
        await db.collection("users").doc(this.userId).update({
          vision: this.editVision,
          bio: this.editBio,
        });
        this.$store.dispatch("user/setUserVision", this.editVision);
        this.$store.dispatch("user/setUserBio", this.editBio);
        this.resetEditAbout();
      } catch (error) {
        alert(error);
      }
    },
    formatEventTimes(start, end) {
      const formattedStart = moment(start).format("h");
      const formattedEnd = moment(end).format("h a");
      return `${formattedStart}-${formattedEnd}`;
    },
    getEventColor(event) {
      const foundSpace = this.$store.state.space.spaces.find(
        (space) => space.id === event.spaceId
      );
      return foundSpace ? foundSpace.color : "grey";
    },
    getGoalProgress(goal) {
      const progress = Math.round((goal.numCompleted / goal.numToComplete) * 100);
      if (Number.isNaN(progress)) {
        return 0;
      } else {
        return progress;
      }
    },
    getSpace(goal) {
      return this.$store.state.space.spaces.find((space) => space.id === goal.spaceId);
    },

    // goal methods
    formattedDueDate(date) {
      return moment(date).format("MMM Do");
    },
    async addNewGoal() {
      this.newGoalDialog = false;
      this.savingSnackbar = true;
      try {
        await db.collection("users").doc(this.userId).collection("goals").add({
          name: this.newGoal.name,
          spaceId: this.newGoal.spaceId,
          completionDate: this.newGoal.completionDate,
          isComplete: false,
          numCompleted: 0,
          numToComplete: 0,
          description: this.newGoal.description,
        });
        this.newGoal = {
          name: "",
          spaceId: "",
          completionDate: "",
          description: "",
        };
        this.savingSnackbar = false;
        this.successSnackbar = true;
      } catch (error) {
        this.errorMsg = error.message;
        this.savingSnackbar = false;
        this.errorSnackbar = true;
      }
    },
  },
};
</script>

<style scoped>
.repeating-gradient {
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 0, 0, 0.25),
    rgba(255, 0, 0, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 10px
  );
}
</style>
