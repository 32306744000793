<template>
  <v-container fluid fill-height>
    <home-nav-bar />
    <v-row class="fill-height mt-2 mx-3" no-gutters>
      <v-col class="fill-height">
        <v-container fluid class="rounded-t-lg page-color" style="height: 100%">
          <div class="page-color">
            <v-row>
              <v-col>
                <v-card flat class="rounded-lg">
                  <v-card-title>About Apollo Planning</v-card-title>
                  <v-card-text>
                    <p>Apollo Planning is an tool designed to help users better organize personal goals and 
                      create actionable plans for their life directed toward achieving their goals. Among the 
                      suite of services that Apollo provides, some of the most prominent include the following:
                    </p>
                    <ul>
                      <li>Goal-Setting platform with the Goal service</li>
                      <li>Track goal progress with the Spaces and Goals services</li>
                      <li>Create plans using the Calendar and Task services</li>
                      <li>Create accountability groups and socialize with others with similar goals as you (coming soon)</li>
                    </ul>
                    <p>We, as the developers of Apollo, hope we can help others achieve their personal goals, better balance
                      their lives, and better manage their time. Feel free to contact us with any questions or comments. Happy goal-setting!
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn disabled>
                      <v-icon left>mdi-discord</v-icon>
                      Join the Discord
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <!-- Team Section -->
            <v-row>
              <v-col>
                <v-toolbar flat class="rounded-lg">
                  <v-toolbar-title>Meet the Team</v-toolbar-title>
                </v-toolbar>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-card flat class="rounded-lg">
                  <v-img height="200" src="../assets/hunter.png" />
                  <v-card-title>Hunter Hansen</v-card-title>
                  <v-card-subtitle>Full-Stack Developer</v-card-subtitle>
                  <v-card-text>
                    <p>
                      Hunter is a student at Brigham Young University and
                      part-time fullstack developer. He is passionate about
                      learning new technologies and building web applications
                      that provide real value to a small group of people.
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="black"
                      dark
                      href="https://github.com/hunterrhansen"
                    >
                      <v-icon left>mdi-github</v-icon>
                      Github
                    </v-btn>
                    <v-btn
                      color="pink"
                      dark
                      href="https://www.instagram.com/hunterrhansen/"
                    >
                      <v-icon left>mdi-instagram</v-icon>
                      Instagram
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card flat class="rounded-lg">
                  <v-img height="200" src="../assets/Chad.png" />
                  <v-card-title>Chad Sauder</v-card-title>
                  <v-card-subtitle>Full-Stack Developer</v-card-subtitle>
                  <v-card-text>
                    <p>
                      Chad is a student at Brigham Young University and part-time 
                      fullstack developer. He loves creating software that can improve
                      peoples lives. Chad has always been a goal oriented person and 
                      hopes others can be too with Apollo.
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="black"
                      dark
                      href="https://github.com/chadsauder"
                    >
                      <v-icon left>mdi-github</v-icon>
                      Github
                    </v-btn>
                    <v-btn
                      color="pink"
                      dark
                      href="https://www.instagram.com/chad.sauder/"
                    >
                      <v-icon left>mdi-instagram</v-icon>
                      Instagram
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HomeNavBar from "../components/HomeNavBar.vue";
export default {
  name: "About",
  components: {
    HomeNavBar,
  },
};
</script>

<style lang="scss" scoped>
.page-color {
  background-color: #e8eaf6;
}
</style>
