import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueGapi from 'vue-gapi'

Vue.config.productionTip = false

Vue.use(VueGapi, {
  apiKey: 'AIzaSyAXR2fbgWOEJJT9L5tinCRkfdIQFLY_uW4',
  clientId: '633947240556-lar2je2gb19f4f19pjnnlk0vom1qhjpr.apps.googleusercontent.com',
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
  scope: 'https://www.googleapis.com/auth/calendar'
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
