import { firestoreAction } from "vuexfire";
import { db } from "../../firebase/firebase";

const namespaced = true;

const state = {
  spaces: [],
  space: null,
};

const mutations = {
  SET_SPACE(state, payload) {
    state.space = payload;
  }
}

const actions = {
  setSpace({commit}, id) {
    const space = state.spaces.find(space => space.id === id);
    commit("SET_SPACE", space);
  },
  bindSpacesRef: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "spaces",
      db.collection("users").doc(context.rootState.user.id).collection("spaces")
    );
  }),
  unbindSpacesRef: firestoreAction((context) => {
    return context.unbindFirestoreRef("spaces");
  }),
};

const getters = {
  spacesWithId(state) {
    return state.spaces.map(space => ({ ...space, id: space.id }))
  },
  getSpace: (state) => (id) => {
    return state.spaces.find(space => space.id === id);
  },
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
