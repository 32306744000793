<template>
  <v-container fluid fill-height >
    <nav-bar />
    <side-drawer />
    <v-row class="fill-height mt-6 mx-0" no-gutters>
      <v-col class="fill-height">
        <v-container fluid class="rounded-t-lg page-color" style="height: 100%">
          <div class="page-color">
            <!-- layout header -->
            <slot name="header"></slot>

            <!-- layout body -->
            <slot name="body"></slot>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import SideDrawer from "../components/SideDrawer.vue";

export default {
  name: "PageLayout",
  components: {
    NavBar,
    SideDrawer,
  },
  computed: {
  },
  data() {
    return {
      //drawer: true,
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.page-color {
  background-color: #e8eaf6;
}
</style>
