<template>
  <v-app-bar
    v-if="$route.name !== 'SignIn'"
    app
    flat
    clipped-left
    clipped-right
    color="white"
  >
    <router-link style="text-decoration: none; color: black" to="/">
      <v-avatar size="30" tile>
        <img src="../assets/apollo-icon.svg" />
      </v-avatar>
      <span class="ml-2 text-h6">APOLLO</span>
      <small class="ml-1">BETA</small>
    </router-link>
    <v-btn icon @click="drawerState = !drawerState" class="ml-10">
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <v-spacer />
    <v-menu
      v-if="userId != null"
      nudge-bottom="50"
      nudge-left="50"
      rounded="lg"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge bottom color="success" dot offset-x="10" offset-y="10">
          <v-avatar size="40" v-bind="attrs" v-on="on">
            <v-img :src="userPhoto"></v-img>
          </v-avatar>
        </v-badge>
      </template>
      <v-card>
        <v-card-title class="text-subtitle-2">{{ username }}</v-card-title>
        <v-card-subtitle>{{ userEmail }}</v-card-subtitle>
        <v-divider></v-divider>
        <v-list>
          <v-list-item-group color="primary">
            <v-list-item to="/settings">
              <v-list-item-content>
                <v-list-item-title>Account Settings</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logoutUser">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
    <v-menu v-else nudge-bottom="55" :close-on-click="closeOnContentClick">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon dark v-bind="attrs" v-on="on" class="ml-4 mr-1">
          <v-avatar size="36" color="primary">
            <v-icon>mdi-rocket-launch-outline</v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-list nav dense>
        <v-list-item-group>
          <v-list-item>
            <v-btn to="/sign-in" color="primary" dark depressed>Login</v-btn>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import { firebaseAuth } from "../firebase/firebase";

export default {
  name: "NavBar",
  data() {
    return {
      closeOnContentClick: false,
    };
  },
  methods: {
    async logoutUser() {
      try {
        firebaseAuth.signOut();
        this.$store.dispatch("user/clearUserData");
        this.$router.push("/");
      } catch (e) {
        alert(e);
      }
    },
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.id,
      username: (state) => state.user.name,
      userEmail: (state) => state.user.email,
      userPhoto: (state) => state.user.photoURL,
    }),
    drawerState: {
      get() {
        return this.$store.getters.drawerState;
      },
      set(v) {
        return this.$store.commit("TOGGLE_DRAWER_STATE", v);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
