import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import { vuexfireMutations } from "vuexfire";

import user from "./modules/user";
import goal from "./modules/goal";
import task from "./modules/task";
import event from "./modules/event";
import space from "./modules/space";
import motivator from "./modules/motivator";
import setting from "./modules/setting";

const vuexLocal = new VuexPersist({
    storage: window.localStorage,
});

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loading: false,
        drawerState: false
    },
    mutations: {
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        TOGGLE_DRAWER_STATE(state, payload) {
            state.drawerState = payload;
        },
        ...vuexfireMutations
    },
    getters: {
        drawerState: (state) => state.drawerState
    },
    actions: {
        toggleAppLoading({ commit }, loading) {
            commit("SET_LOADING", loading);
        }
    },
    modules: {
        user,
        goal,
        task,
        event,
        space,
        motivator,
        setting
    },
    plugins: [vuexLocal.plugin],
});