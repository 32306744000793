<template>
  <v-container fill-height fluid>
    <home-nav-bar></home-nav-bar>

    <v-container>
      <v-row justify="space-between" align="center" id="top">
        <v-col cols="12" md="6" align="center">
          <h1 class="banner">One small step for you,</h1>
          <h1 class="banner">one giant leap for your future.</h1>
          <v-row justify="center" class="my-5">
            <v-btn
              v-if="userId == null"
              to="/sign-in"
              min-height="50"
              max-width="200"
              rounded
              dark
              color="primary"
              >Get Started</v-btn
            >
            <v-btn
              v-else
              to="/overview"
              min-height="50"
              max-width="200"
              rounded
              dark
              color="primary"
              >Get Started</v-btn
            >
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-img max-height="700" src="../assets/business-startup.svg"></v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-row id="middle" align="center">
      <v-container grid-list-xl>
        <v-row justify="center" align="center" class="my-5">
          <v-col cols="12" sm="4">
            <v-card
              color="grey darken-4"
              dark
              align="center"
              class="mx-4"
              min-height="500"
            >
              <v-container>
                <v-img width="250" src="../assets/prep-rocket.svg"></v-img>
              </v-container>
              <v-card-title class="justify-center text-h4"
                >Set Goals</v-card-title
              >
              <v-card-text>
                <v-container class="text-h6">
                  Set and customize personal goals for maximum productivity.
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card
              color="grey darken-4"
              dark
              align="center"
              class="mx-4"
              min-height="500"
            >
              <v-container>
                <v-img width="250" src="../assets/rocket-launch.svg"></v-img>
              </v-container>

              <v-card-title class="justify-center text-h4"
                >Make Plans</v-card-title
              >
              <v-card-text>
                <v-container class="text-h6">
                  Put your time where your mouth is. Set out practical times to
                  achieve your goals. Organize your plans in one place.
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card
              color="grey darken-4"
              dark
              align="center"
              class="mx-4"
              min-height="500"
            >
              <v-container>
                <v-img width="250" src="../assets/riding-rocket.svg"></v-img>
              </v-container>
              <v-card-title class="justify-center text-h4"
                >Track Progress</v-card-title
              >
              <v-card-text>
                <v-container class="text-h6">
                  Easily track the progress of your goals and tasks through an
                  integrated, simple dashboard.
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row id="app-section" align="center">
      <v-container grid-list-xl>
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" align="center" justify="center">
            <v-img src="../assets/mobile-application.svg"></v-img>
          </v-col>
          <v-col cols="12" md="6" align="center">
            <h1 class="banner-sm">Take your goals to go!</h1>
            <p class="text-h5">
              Coming soon to your favorite <br />
              mobile platforms...
            </p>
            <v-row>
              <v-col>
                <v-btn rounded disabled class="mr-2">
                  Download
                  <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn rounded disabled class="mr-2">
                  <v-icon>mdi-android</v-icon>
                </v-btn>
                <v-btn disabled rounded>
                  <v-icon>mdi-apple-ios</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row id="community-section" align="center">
      <v-container grid-list-xl>
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" align="center">
            <h1 class="banner-sm">Connect with the Apollo Community.</h1>
            <p class="text-h5">Coming soon to your Discord...</p>
            <v-row>
              <v-col>
                <v-btn rounded disabled>
                  <v-icon class="mr-1">mdi-discord</v-icon>
                  Join Discord
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" align="center" justify="center">
            <v-img src="../assets/city.svg"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-footer dark padless absolute bottom class="text-center">
      <v-card-text>
        <v-btn class="mx-4" icon href="https://www.instagram.com/">
          <v-icon size="24px"> mdi-instagram </v-icon>
        </v-btn>
        <v-btn class="mx-4" icon href="https://www.linkedin.com/">
          <v-icon size="24px"> mdi-linkedin </v-icon>
        </v-btn>
        <v-btn
          class="mx-4"
          icon
          href="https://chrome.google.com/webstore/category/extensions"
        >
          <v-icon size="24px"> mdi-google-chrome </v-icon>
        </v-btn>
      </v-card-text>
      <v-container>
        <v-divider></v-divider>
      </v-container>

      <v-card-text class="white--text">
        Developed by
        <a href="https://github.com/chadsauder">Chad Sauder</a>
        and
        <a href="https://github.com/hunterrhansen">Hunter Hansen</a>
      </v-card-text>
    </v-footer>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { firebaseAuth } from "../firebase/firebase";
import HomeNavBar from "../components/HomeNavBar.vue";

export default {
  name: "Home",
  components: {
    HomeNavBar,
  },
  data() {
    return {
      icons: ["mdi-home", "mdi-email", "mdi-calendar", "mdi-delete"],
    };
  },
  computed: mapState({
    userId: (state) => state.user.id,
    username: (state) => state.user.name,
    userEmail: (state) => state.user.email,
    userPhoto: (state) => state.user.photoURL,
  }),
  methods: {
    async logoutUser() {
      try {
        firebaseAuth.signOut();
        this.$store.dispatch("user/clearUserData");
        this.$router.push("/");
      } catch (e) {
        alert(e);
      }
    },
  },
};
</script>

<style scoped>
#top {
  min-height: 1000px;
}
#middle {
  background-color: #f6f6f6;
  min-height: 750px;
}
#app-section {
  min-height: 750px;
}
#community-section {
  background-color: #f6f6f6;
  min-height: 750px;
  padding-bottom: 200px;
}
.spacer {
  aspect-ratio: 900 / 100;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.tilt {
  background-image: url(../assets/tilt.svg);
}
.banner {
  font-size: 3rem;
}
.banner-sm {
  font-size: 2rem;
}
.app-bar-title {
  font-size: 30px;
  font-weight: bold;
}
.beta {
  font-weight: bold;
  color: blue;
}
.user-box {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
