<template>
  <PageLayout>
    <template v-slot:header>
      <PageHeader title="Tasks" :breadcrumbs="breadcrumbs" />
    </template>
    <template v-slot:body>
      <!-- right side navigation drawer -->
      <v-navigation-drawer clipped right app v-model="sideNavBar" width="300">
        <v-container>
          <!-- date selector -->
          <v-card outlined class="rounded-lg text-center">
            <v-toolbar flat>
              <v-spacer />
              <v-btn
                outlined
                :color="isToday ? 'primary' : 'black'"
                @click="setToday()"
                >{{ type === "day" ? "Today" : `This ${type}` }}
              </v-btn>
              <v-spacer />
              <v-menu bottom right offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="black" v-bind="attrs" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-spacer />
            </v-toolbar>
            <v-date-picker
              v-model="selectedDay"
              no-title
              color="primary"
              width="255"
            />
          </v-card>

          <!-- space selector -->
          <v-card class="mt-3 rounded-lg" outlined>
            <v-card-title>Spaces</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-checkbox
                v-for="(space, i) in $store.state.space.spaces"
                :key="i"
                dense
                ripple
                multiple
                :value="space.id"
                :color="space.color"
                :label="space.name"
                v-model="checkedSpaces"
              ></v-checkbox>
            </v-card-text>
          </v-card>
        </v-container>
      </v-navigation-drawer>

      <!-- tasks table -->
      <v-row class="mt-0">
        <v-col>
          <v-card outlined flat class="rounded-lg">
            <v-toolbar flat>
              <v-toolbar-title class="text-h6">{{ date }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <span class="text-h6 mr-1" v-if="!screenIsMobile">{{ progress }}%</span>
              <v-progress-circular
                :value="progress"
                color="primary"
                class="mx-1"
              ></v-progress-circular>
            </v-toolbar>
            <v-card-text>
              <v-data-table
                hide-default-footer
                :headers="taskTableHeaders"
                :items="filteredTasks"
                sort-by="spaceId"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-menu bottom right v-if="screenIsMobile">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          class="ml-1"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span>{{ toggleToLabel[toggleTasks] }}</span>
                          <v-icon right> mdi-menu-down </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="toggleTasks = 'all'">
                          <v-list-item-title>All</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="toggleTasks = 'remaining'">
                          <v-list-item-title
                            >Remaining ({{
                              filteredNumRemainingTasks
                            }})</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item @click="toggleTasks = 'completed'">
                          <v-list-item-title
                            >Completed ({{
                              filteredNumCompletedTasks
                            }})</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-btn-toggle
                      v-else
                      v-model="toggleTasks"
                      color="primary"
                      mandatory
                    >
                      <v-btn value="all" depressed>All</v-btn>
                      <v-btn value="remaining" depressed
                        >Remaining ({{ filteredNumRemainingTasks }})</v-btn
                      >
                      <v-btn value="completed" depressed
                        >Completed ({{ filteredNumCompletedTasks }})</v-btn
                      >
                    </v-btn-toggle>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          color="primary"
                          @click="sideNavBar = !sideNavBar"
                        >
                          <v-icon>mdi-filter-variant</v-icon>
                        </v-btn>
                      </template>
                      <span>Open/Close Filter Bar</span>
                    </v-tooltip>
                  </v-toolbar>
                </template>
                <template v-slot:item.spaceId="{ item }">
                  <v-chip
                    v-if="item.spaceId"
                    :color="getSpaceColor(item.spaceId)"
                    dark
                    >{{ getSpaceName(item.spaceId) }}</v-chip
                  >
                </template>
                <template v-slot:item.goalId="{ item }">
                  <v-chip
                    v-if="item.goalId"
                    :color="getSpaceColor(item.spaceId)"
                    dark
                    >{{ getGoalName(item.goalId) }}</v-chip
                  >
                  <v-chip v-else color="grey lighten-2">None</v-chip>
                </template>
                <template v-slot:item.isComplete="{ item }">
                  <v-checkbox
                    v-model="item.isComplete"
                    @click="completeTask(item)"
                  ></v-checkbox>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon color="red" @click="deleteTask(item.id)">
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:no-data>
                  <p>Looks like you do not have any tasks! Congrats</p>
                </template>
              </v-data-table>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions v-if="addTaskFields">
              <v-toolbar flat>
                <v-text-field
                  height="50"
                  hide-details="auto"
                  outlined
                  label="Task"
                  class="mr-1"
                  v-model="selectedTask.name"
                ></v-text-field>
                <v-select
                  class="mr-1"
                  height="50"
                  hide-details="auto"
                  v-model="selectedTask.spaceId"
                  :items="$store.state.space.spaces"
                  outlined
                  item-text="name"
                  item-value="id"
                  chips
                  label="Space"
                ></v-select>
                <v-btn
                  @click="resetTaskDialog()"
                  color="error"
                  class="mr-1"
                  fab
                  height="55"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn color="success" @click="addTask()" fab height="55">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </v-toolbar>
            </v-card-actions>

            <v-card-actions v-else>
              <v-spacer></v-spacer>
              <v-tooltip bottom v-if="!addTaskFields">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    fab
                    color="primary"
                    class="ma-2"
                    @click="addTaskFields = true"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New Task</span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </PageLayout>
</template>

<script>
import moment from "moment";
import PageLayout from "../layouts/PageLayout";
import PageHeader from "../components/PageHeader";
import { mapState, mapGetters } from "vuex";
import { db } from "../firebase/firebase";

export default {
  name: "Tasks",
  components: {
    PageLayout,
    PageHeader,
  },
  data: () => ({
    type: "week",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
    },
    toggleToLabel: {
      all: "All",
      remaining: "Remaining",
      completed: "Completed",
    },
    breadcrumbs: [
      {
        icon: "mdi-home",
        text: "",
        href: "/",
      },
      {
        text: "Tasks",
      },
    ],
    taskTableHeaders: [
      {
        text: "",
        value: "isComplete",
      },
      {
        text: "TASK",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "SPACE",
        value: "spaceId",
      },
      {
        text: "GOAL",
        value: "goalId",
      },
      { text: "ACTIONS", value: "actions", sortable: false },
    ],
    toggleTasks: "all",

    // Side Nav Drawer
    checkedSpaces: [],
    selectedDay: "",
    sideNavBar: false,
    addTaskFields: false,
    editTaskDialog: false,
    confirmDeleteDialog: false,
    selectedIndex: -1,

    //datePickerEvents: [],

    selectedTask: {},
  }),
  mounted() {
    this.setToday();
    this.checkAllSpaces();
  },
  watch: {
    tasks: function () {
      this.checkAllSpaces();
    },
  },
  computed: {
    screenIsMobile() {
      return this.$vuetify.breakpoint.width < 960;
    },
    isToday() {
      return this.selectedDay === moment(Date.now()).format("yyyy-MM-DD");
    },
    date() {
      if (this.type === "day") {
        return moment(this.selectedDay).format("ddd, MMMM Do, YYYY");
      } else if (this.type === "week") {
        return (
          moment(this.selectedDay).startOf("week").format("MMM DD") +
          " - " +
          moment(this.selectedDay).endOf("week").format("MMM Do, YYYY")
        );
      } else {
        return moment(this.selectedDay.month).format("MMMM YYYY");
      }
    },
    remainingTasks() {
      return this.filteredTasksBySpaceAndDate.filter(
        (task) => task.isComplete === false
      );
    },
    completedTasks() {
      return this.filteredTasksBySpaceAndDate.filter(
        (task) => task.isComplete === true
      );
    },
    filteredTasks() {
      // first, filter by all, remaining, or completed button toggle
      let filterByCompletion;
      if (this.toggleTasks === "completed") {
        filterByCompletion = this.completedTasks;
      } else if (this.toggleTasks === "remaining") {
        filterByCompletion = this.remainingTasks;
      } else {
        filterByCompletion = this.filteredTasksBySpaceAndDate;
      }
      return filterByCompletion;
    },
    filteredTasksBySpaceAndDate() {
      // first, filter by space checkboxes
      let filteredBySpace = this.$store.state.task.tasks.filter((task) =>
        this.checkedSpaces.includes(task.spaceId)
      );

      // next, filter by selected day/week/month
      if (this.type === "day") {
        return filteredBySpace.filter((task) => task.date === this.selectedDay);
      } else if (this.type === "week") {
        const weekStart = new Date(
          moment(this.selectedDay).startOf("week")
        ).getTime();
        const weekEnd = new Date(
          moment(this.selectedDay).endOf("week")
        ).getTime();
        return filteredBySpace.filter(
          (task) =>
            new Date(task.date).getTime() >= weekStart &&
            new Date(task.date).getTime() <= weekEnd
        );
      } else {
        const monthStart = new Date(
          moment(this.selectedDay).startOf("month")
        ).getTime();
        const monthEnd = new Date(
          moment(this.selectedDay).endOf("month")
        ).getTime();
        return filteredBySpace.filter(
          (task) =>
            new Date(task.date).getTime() >= monthStart &&
            new Date(task.date).getTime() <= monthEnd
        );
      }
    },
    filteredNumRemainingTasks() {
      return this.filteredTasksBySpaceAndDate.filter(
        (task) => task.isComplete === false
      ).length;
    },
    filteredNumCompletedTasks() {
      return this.filteredTasksBySpaceAndDate.filter(
        (task) => task.isComplete === true
      ).length;
    },
    progress() {
      let completedTasks = this.filteredTasks.filter(
        (task) => task.isComplete === true
      );

      return this.filteredTasks.length > 0
        ? Math.round((completedTasks.length / this.filteredTasks.length) * 100)
        : 0;
    },
    ...mapState({
      userId: (state) => state.user.id,
      userPhoto: (state) => state.user.photoURL,
      username: (state) => state.user.name,
      spaces: (state) => state.space.spaces,
    }),
    ...mapGetters({
      tasks: "task/tasksWithColor",
    }),
  },

  methods: {
    async addTask() {
      try {
        db.collection("users").doc(this.userId).collection("tasks").add({
          name: this.selectedTask.name,
          isComplete: false,
          date: this.selectedDay,
          spaceId: this.selectedTask.spaceId,
        });

        this.resetTaskDialog();
      } catch (e) {
        alert(e);
      }
    },
    async deleteTask(id) {
      try {
        db.collection("users")
          .doc(this.userId)
          .collection("tasks")
          .doc(id)
          .delete();
        this.resetTaskDialog();
      } catch (e) {
        alert(e);
      }
    },
    async completeTask(task) {
      try {
        db.collection("users")
          .doc(this.userId)
          .collection("tasks")
          .doc(task.id)
          .update({ isComplete: task.isComplete });
      } catch (e) {
        alert(e);
      }
    },
    async editTask() {
      try {
        db.collection("users")
          .doc(this.userId)
          .collection("tasks")
          .doc(this.selectedTask.id)
          .set(this.selectedTask);
        this.resetTaskDialog();
      } catch (e) {
        alert(e);
      }
    },

    // Other Methods
    getSpaceColor(id) {
      const space = this.$store.state.space.spaces.find(
        (space) => space.id === id
      );
      return space.color;
    },
    getSpaceName(id) {
      const space = this.$store.state.space.spaces.find(
        (space) => space.id === id
      );
      return space.name;
    },
    getGoalName(id) {
      const goal = this.$store.state.goal.goals.find((goal) => goal.id === id);
      return goal.name;
    },
    checkAllSpaces() {
      for (let i = 0; i < this.$store.state.space.spaces.length; i++) {
        this.checkedSpaces.push(this.$store.state.space.spaces[i].id);
      }
    },
    setDelete(i) {
      this.confirmDeleteDialog = true;
      this.selectedIndex = i;
      this.selectedTask = this.$store.state.task.tasks[i];
    },
    resetTaskDialog() {
      this.confirmDeleteDialog = false;
      this.addTaskFields = false;
      this.selectedIndex = -1;
      this.selectedTask = {};
    },

    // Time Methods
    prev() {
      if (this.type === "day") {
        this.selectedDay = moment(this.selectedDay)
          .subtract(1, "days")
          .format("yyyy-MM-DD");
      } else if (this.type === "week") {
        this.selectedDay = moment(this.selectedDay)
          .subtract(7, "days")
          .format("yyyy-MM-DD");
      } else if (this.type === "month") {
        this.selectedDay = moment(this.selectedDay)
          .subtract(1, "months")
          .format("yyyy-MM-DD");
      }
    },
    next() {
      if (this.type === "day") {
        this.selectedDay = moment(this.selectedDay)
          .add(1, "days")
          .format("yyyy-MM-DD");
      } else if (this.type === "week") {
        this.selectedDay = moment(this.selectedDay)
          .add(7, "days")
          .format("yyyy-MM-DD");
      } else if (this.type === "month") {
        this.selectedDay = moment(this.selectedDay)
          .add(1, "months")
          .format("yyyy-MM-DD");
      }
    },
    setToday() {
      this.selectedDay = moment(Date.now()).format("yyyy-MM-DD");
    },
  },
};
</script>

<style scoped></style>
