import { firestoreAction } from "vuexfire";
import { db } from "../../firebase/firebase";

const namespaced = true;

const state = {
  goals: [],
};

const actions = {
  bindGoalsRef: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "goals",
      db.collection("users").doc(context.rootState.user.id).collection("goals")
    );
  }),
  unbindGoalsRef: firestoreAction((context) => {
    return context.unbindFirestoreRef("goals");
  }),
};

const getters = {
  goalsDropdown(state) {
    return [...state.goals, { name: "None", id: "" }];
  },
  goalsBySpace: (state) => (id) => {
    return state.goals.filter(goal => goal.spaceId === id);
  },
  numGoalsBySpace: (state) => (id) => {
    return state.goals.filter(goal => goal.spaceId === id).length;
  }
}

export default {
  namespaced,
  state,
  actions,
  getters
};
