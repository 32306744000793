<template>
  <v-app v-if="!$store.state.loading">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
  <v-app v-else>
    <v-main>
      <v-container fill-height>
        <v-row align="center">
          <v-col align-self="center" class="text-center">
            <v-progress-circular size="150" width="10" indeterminate color="primary">
              <v-img height="50" width="50" src="./assets/apollo-icon-grey.svg"></v-img>
            </v-progress-circular>
            <p class="text-h6 mt-5">Prepare for takeoff...</p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: "App",
  async created() {
    this.$store.dispatch("toggleAppLoading", true);
    if (this.$store.state.user.id) {
      await this.$store.dispatch("event/getEvents", this.$store.state.user.id);
      await this.$store.dispatch("task/bindTasksRef");
      await this.$store.dispatch("goal/bindGoalsRef");
      await this.$store.dispatch("space/bindSpacesRef");
      await this.$store.dispatch("motivator/bindMotivatorsRef");
      await this.$store.dispatch("setting/bindSettingsRef");
    }
    this.$store.dispatch("toggleAppLoading", false);
  },
};
</script>
