import { firestoreAction } from "vuexfire";
import { db } from "../../firebase/firebase";

const namespaced = true;

const state = {
  motivators: [],
};

const actions = {
  bindMotivatorsRef: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "motivators",
      db.collection("users").doc(context.rootState.user.id).collection("motivators")
    );
  }),
  unbindMotivatorsRef: firestoreAction((context) => {
    return context.unbindFirestoreRef("motivators");
  }),
};

const getters = {
  motivatorsBySpace: (state) => (id) => {
    return state.motivators.filter(motivator => motivator.spaceId === id);
  },
  motivatorsWithColor(state, getters, rootState) {
    let motivators = state.motivators;
    
    for (let i = 0; i < motivators.length; i++) {
      let goal = rootState.goal.goals.find(goal => goal.id === motivators[i].goalId);
      if (goal) {
        let space = rootState.space.spaces.find(space => space.id === goal.spaceId);
        motivators[i].color = space.color;
      }
    }

    return motivators;
  },
}

export default {
  namespaced,
  state,
  actions,
  getters
};
