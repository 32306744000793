import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home'
import Calendar from '../views/Calendar'
import Settings from '../views/Settings'
import About from '../views/About'
import SignIn from '../views/SignIn'
import Tasks from '../views/Tasks'
import Overview from "../views/Overview"
import Goal from "../views/Goal"
import MySpaces from "../views/MySpaces"
import Error404 from "../views/Error404"

import { firebaseAuth } from '@/firebase/firebase'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/sign-in',
        name: 'SignIn',
        component: SignIn
    },
    {
        path: '/calendar',
        name: 'Calendar',
        component: Calendar,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/overview',
        name: 'Overview',
        component: Overview,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/my-spaces',
        name: 'MySpaces',
        component: MySpaces,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/tasks',
        name: 'Tasks',
        component: Tasks,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/goals/:id',
        name: 'Goal',
        component: Goal,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/error-404",
        name: "Error404",
        component: Error404,
    },
    {
        path: "*",
        redirect: "error-404"
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async(to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    firebaseAuth.onAuthStateChanged((user) => {
        if (user == null && requiresAuth) {
            next('sign-in')
        } else {
            next()
        }
    })
})

export default router