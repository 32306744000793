const namespaced = true;

const state = {
  id: null,
  name: null,
  vision: null,
  bio: null,
  email: null,
  photoURL: null,
};

const mutations = {
  SET_USER_DATA: (state, payload) => {
    state.id = payload.id;
    state.name = payload.name;
    state.vision = payload.vision;
    state.bio = payload.bio;
    state.email = payload.email;
    state.photoURL = payload.photoURL;
  },
  CLEAR_USER_DATA: (state) => {
    state.id = null;
    state.name = null;
    state.bio = null;
    state.vision = null;
    state.email = null;
    state.photoURL = null;
  },
  SET_USER_PHOTO: (state, payload) => {
    state.photoURL = payload;
  },
  SET_USER_NAME: (state, payload) => {
    state.name = payload;
  },
  SET_USER_BIO: (state, payload) => {
    state.bio = payload;
  },
  SET_USER_VISION: (state, payload) => {
    state.vision = payload;
  },
};

const actions = {
  setUserData({ commit }, userData) {
    commit("SET_USER_DATA", userData);
  },
  clearUserData({ commit }, userData) {
    commit("CLEAR_USER_DATA", userData);
  },
  setUserPhoto({ commit }, photo) {
    commit("SET_USER_PHOTO", photo);
  },
  setUserName({ commit }, name) {
    commit("SET_USER_NAME", name);
  },
  setUserVision({ commit }, vision) {
    commit("SET_USER_VISION", vision);
  },
  setUserBio({ commit }, bio) {
    commit("SET_USER_BIO", bio);
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
};
