<template>
  <PageLayout>
    <template v-slot:header>
      <PageHeader title="My Spaces" :breadcrumbs="breadcrumbs" />
    </template>
    <template v-slot:body>
      <v-row class="mt-0">
        <v-col>
          <v-card flat class="rounded-lg">
            <v-card-title class="text-h5 font-weight-bold">
              Spaces
            </v-card-title>
            <v-btn
              absolute
              color="black"
              dark
              top
              right
              @click="spaceDialog = true"
            >
              <v-icon>mdi-plus</v-icon>
              new space
            </v-btn>

            <v-navigation-drawer
              v-model="spaceDialog"
              right
              app
              temporary
              width="420"
            >
              <v-card flat tile>
                <v-toolbar flat color="grey lighten-3">
                  <v-toolbar-title>New Space</v-toolbar-title>
                  <v-spacer />
                  <v-btn icon @click="resetSpaceDialog">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-subtitle
                  >Create a new space to organize your goals, tasks, events, and
                  habits.</v-card-subtitle
                >
                <v-card-text>
                  <v-form ref="addSpaceForm">
                    <v-container>
                      <v-row>
                        <v-col offset="1">
                          <v-text-field
                            label="Space Title"
                            v-model="newSpace.name"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col>
                          <span>Color</span>
                        </v-col>
                        <v-col cols="10">
                          <v-color-picker
                            v-model="newSpace.color"
                            :swatches="colors"
                            hide-canvas
                            hide-inputs
                            hide-sliders
                            show-swatches
                          ></v-color-picker>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col>
                          <span>Icon</span>
                        </v-col>
                        <v-col cols="10" align="center">
                          <v-item-group
                            class="d-flex align-center"
                            v-model="selectedIcon"
                          >
                            <v-row>
                              <v-col v-for="icon in icons" :key="icon" cols="2">
                                <v-item v-slot="{ active, toggle }">
                                  <v-avatar
                                    tile
                                    class="d-flex align-center rounded-lg"
                                    @click="toggle"
                                    :color="
                                      active ? 'primary' : 'grey lighten-3'
                                    "
                                  >
                                    <v-icon :dark="active">
                                      {{ icon }}
                                    </v-icon>
                                  </v-avatar>
                                </v-item>
                              </v-col>
                            </v-row>
                          </v-item-group>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    :loading="isLoading"
                    :disabled="!isValid"
                    color="primary"
                    depressed
                    @click="addSpace"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-navigation-drawer>

            <v-card-text>
              <v-list two-line v-if="$store.state.space.spaces.length > 0">
                <template v-for="(space, index) in $store.state.space.spaces">
                  <v-divider :key="`divider-${index}-top`" />
                  <v-list-group
                    :key="`list-group-${index}`"
                    active-class="no-active"
                    no-action
                    color="none"
                  >
                    <template v-slot:activator>
                      <v-list-item>
                        <v-list-item-avatar
                          :color="space.color"
                          tile
                          class="rounded-lg"
                        >
                          <v-icon dark>{{ space.icon }}</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="text-h6">{{
                            space.name
                          }}</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ space.description }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>

                    <v-container
                      v-if="
                        activeGoalsInSpace(space.id).length > 0 ||
                        completedGoalsInSpace(space.id).length > 0
                      "
                      :key="`list-${index}`"
                    >
                      <h3 class="my-4">Active goals</h3>
                      <v-list
                        v-if="activeGoalsInSpace(space.id).length > 0"
                        two-line
                      >
                        <v-list-item
                          v-for="goal in activeGoalsInSpace(space.id)"
                          :key="`list-item-${goal.id}`"
                        >
                          <v-list-item-title>
                            {{ goal.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="goal.description !== ''">
                            {{ goal.description }}
                          </v-list-item-subtitle>
                          <v-list-item-action>
                            <v-btn icon :to="`goals/${goal.id}`">
                              <v-icon color="grey lighten-1"
                                >mdi-information</v-icon
                              >
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <p v-else>No active goals in this space.</p>
                      <h3 class="my-4">Completed Goals</h3>
                      <v-list
                        v-if="completedGoalsInSpace(space.id).length > 0"
                        two-line
                      >
                        <v-list-item
                          v-for="goal in completedGoalsInSpace(space.id)"
                          :key="`list-item-${goal.id}`"
                          :to="`goals/${goal.id}`"
                        >
                          <v-list-item-title>
                            {{ goal.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="goal.description !== ''">
                            {{ goal.description }}
                          </v-list-item-subtitle>
                          <v-list-item-action>
                            <v-btn icon>
                              <v-icon color="grey lighten-1"
                                >mdi-chevron-right</v-icon
                              >
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <p v-else>No completed goals in this space.</p>
                    </v-container>
                    <v-container v-else :key="`empty-container-${index}`">
                      <p class="my-4">No goals in this space.</p>
                      <v-btn to="/overview" dark color="black">
                        <v-icon>mdi-plus</v-icon>
                        Add Goal</v-btn
                      >
                    </v-container>
                  </v-list-group>
                </template>
              </v-list>
              <p v-else>You have no spaces! Click 'New Space' to create one!</p>
            </v-card-text>
          </v-card>
          <v-snackbar v-model="savingSnackbar">
            Saving...
            <template v-slot:action="{ attrs }">
              <v-btn icon v-bind="attrs" @click="savingSnackbar = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
          <v-snackbar v-model="successSnackbar">
            Space added
            <template v-slot:action="{ attrs }">
              <v-btn icon v-bind="attrs" @click="successSnackbar = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
          <v-snackbar v-model="errorSnackbar">
            Error encountered while saving space
            <template v-slot:action="{ attrs }">
              <v-btn icon v-bind="attrs" @click="errorSnackbar = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </template>
  </PageLayout>
</template>

<script>
import PageLayout from "../layouts/PageLayout";
import PageHeader from "../components/PageHeader";
import { mapState } from "vuex";
import { db } from "./../firebase/firebase";

export default {
  name: "MySpaces",
  components: {
    PageLayout,
    PageHeader,
  },
  data() {
    return {
      breadcrumbs: [
        {
          icon: "mdi-home",
          text: "",
          href: "/",
        },
        {
          text: "Spaces",
        },
      ],

      // snackbar
      savingSnackbar: false,
      successSnackbar: false,
      errorSnackbar: false,

      // dialog options
      colors: [["#191919"], ["#0F2C67"], ["#CD1818"], ["#34BE82"], ["#FF7F3F"]],
      icons: [
        "mdi-account",
        "mdi-brain",
        "mdi-run",
        "mdi-rocket",
        "mdi-cube-outline",
      ],

      // state
      selectedIcon: 0,
      newSpace: {
        name: "",
        color: "#191919",
        icon: "mdi-account",
      },
      spaceDialog: false,
      isLoading: false,
      errorMsg: "",
    };
  },
  computed: {
    isValid() {
      return this.newSpace.name.length > 0;
    },
    ...mapState({
      userId: (state) => state.user.id,
    }),
  },
  methods: {
    async addSpace() {
      this.isLoading = true;
      this.spaceDialog = false;
      this.savingSnackbar = true;

      try {
        this.newSpace.icon = this.icons[this.selectedIcon];
        await db
          .collection("users")
          .doc(this.userId)
          .collection("spaces")
          .add(this.newSpace);

        this.resetSpaceDialog();
        this.savingSnackbar = false;
        this.successSnackbar = true;
      } catch (error) {
        this.errorMsg = error;
        this.savingSnackbar = false;
        this.errorSnackbar = true;
      }

      this.isLoading = false;
    },
    activeGoalsInSpace(groupId) {
      const goalsInSpace = this.$store.state.goal.goals.filter(
        (goal) => goal.spaceId == groupId
      );
      return goalsInSpace.filter((goal) => goal.isComplete == false);
    },
    completedGoalsInSpace(groupId) {
      const goalsInSpace = this.$store.state.goal.goals.filter(
        (goal) => goal.spaceId == groupId
      );
      return goalsInSpace.filter((goal) => goal.isComplete == true);
    },
    resetSpaceDialog() {
      this.newSpace = {
        name: "",
        color: "#191919",
        icon: "mdi-account",
      };
      this.spaceDialog = false;
    },
  },
};
</script>
