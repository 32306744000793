<template>
  <PageLayout>
    <template v-slot:body>
      <v-row>
        <v-col>
          <!-- Calendar outer most sheet -->
          <v-sheet class="rounded-lg" outlined height="1250">
            <v-toolbar flat class="rounded-t-lg" height="85">
              <v-toolbar-title class="text-h5 font-weight-bold">
                Calendar
              </v-toolbar-title>
              <v-spacer />
              <h5 class="text-h6 font-weight-bold" v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </h5>
            </v-toolbar>
            <v-divider></v-divider>
            <v-row class="mx-1">
              <v-col>
                <v-sheet height="64" color="transparent">
                  <v-toolbar dense flat class="rounded-t-lg mt-5">
                    <v-btn outlined color="primary" @click="setToday">
                      Today
                    </v-btn>
                    <v-btn
                      fab
                      small
                      outlined
                      color="primary"
                      class="ml-1"
                      @click="prev"
                    >
                      <v-icon> mdi-chevron-left </v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      small
                      outlined
                      color="primary"
                      class="ml-1"
                      @click="next"
                    >
                      <v-icon> mdi-chevron-right </v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-menu bottom right v-if="!screenIsMobile">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          class="ml-1"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span>{{ typeToLabel[type] }}</span>
                          <v-icon right> mdi-menu-down </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="type = 'day'">
                          <v-list-item-title>Day</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'week'">
                          <v-list-item-title>Week</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'month'">
                          <v-list-item-title>Month</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <!-- New Event button for later see google calendar implementation -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          @click="sideNavBar = !sideNavBar"
                          class="ml-1"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-filter-variant</v-icon>
                        </v-btn>
                      </template>
                      <span>Toggle Calendar Filters</span>
                    </v-tooltip>
                  </v-toolbar>
                </v-sheet>
                <v-sheet height="1050" color="transparent" class="mt-3">
                  <v-calendar
                    class="rounded-b-lg"
                    ref="calendar"
                    v-model="focus"
                    color="grey lighten-2"
                    :events="filteredEvents"
                    event-ripple
                    :event-color="getEventColor"
                    :type="type"
                    first-interval="4"
                    interval-count="20"
                    styles="overflow-y: auto"
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    @mousedown:event="mouseDownEvent"
                    @mousedown:time="mouseDownTime"
                    @mouseup:time="mouseUpTime"
                    @mousemove:time="mouseMoveOnCalendar"
                    @mouseleave.native="mouseLeaveArea"
                  >
                    <template v-slot:day-body="{ date, week }">
                      <div
                        class="v-current-time"
                        :class="{ first: date === week[0].date }"
                        :style="{ top: nowY }"
                      ></div>
                    </template>
                  </v-calendar>

                  <!-- Menu pop-up for an existing selected element -->
                  <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x
                  >
                    <v-card
                      v-if="createEvent"
                      :loading="isLoading"
                      max-width="550"
                    >
                      <v-card-title>
                        <span class="text-h5">New Event</span>
                      </v-card-title>
                      <v-btn absolute top right icon @click="cancelNewEvent()">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col offset="1">
                              <v-text-field
                                label="Event Title"
                                v-model="selectedEvent.name"
                                required
                              />
                            </v-col>
                          </v-row>
                          <v-row align="center">
                            <v-col cols="1">
                              <v-icon class="mb-1"
                                >mdi-clock-time-four-outline</v-icon
                              >
                            </v-col>
                            <v-col cols="4">
                              <v-menu
                                v-model="dateAddMenuInDialog"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    :value="formattedDate"
                                    label="Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  color="primary"
                                  no-title
                                  v-model="selectedEvent.date"
                                  @input="dateAddMenuInDialog = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="2">
                              <v-menu
                                ref="startTimeAddMenuInDialog"
                                v-model="startTimeAddMenuInDialog"
                                :close-on-content-click="false"
                                :return-value.sync="selectedEvent.startTime"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="formattedStartTime"
                                    label="Start time"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                  v-if="startTimeAddMenuInDialog"
                                  color="primary"
                                  v-model="selectedEvent.startTime"
                                  full-width
                                  no-title
                                  @click:minute="
                                    $refs.startTimeAddMenuInDialog.save(
                                      selectedEvent.startTime
                                    )
                                  "
                                >
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="startTimeAddMenuInDialog = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="
                                      $refs.startTimeAddMenuInDialog.save(
                                        selectedEvent.startTime
                                      )
                                    "
                                  >
                                    OK
                                  </v-btn>
                                </v-time-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="1" align="center">
                              <p class="mt-3">-</p>
                            </v-col>
                            <v-col cols="2">
                              <v-menu
                                ref="endTimeAddMenuInDialog"
                                v-model="endTimeAddMenuInDialog"
                                :close-on-content-click="false"
                                :return-value.sync="selectedEvent.endTime"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="formattedEndTime"
                                    label="End time"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                  v-if="endTimeAddMenuInDialog"
                                  color="primary"
                                  v-model="selectedEvent.endTime"
                                  full-width
                                  no-title
                                  @click:minute="
                                    $refs.endTimeAddMenuInDialog.save(
                                      selectedEvent.endTime
                                    )
                                  "
                                >
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="endTimeAddMenuInDialog = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="
                                      $refs.endTimeAddMenuInDialog.save(
                                        selectedEvent.endTime
                                      )
                                    "
                                  >
                                    OK
                                  </v-btn>
                                </v-time-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                          <v-row align="center">
                            <v-col cols="1">
                              <v-icon class="mb-1">mdi-text</v-icon>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Description"
                                v-model="selectedEvent.description"
                                required
                              />
                            </v-col>
                          </v-row>
                          <v-row align="center">
                            <v-col cols="1">
                              <v-icon class="mb-1">mdi-view-dashboard</v-icon>
                            </v-col>
                            <v-col>
                              <v-select
                                :items="spaceDropdown"
                                item-text="name"
                                item-value="id"
                                label="Add a space to organize your event"
                                v-model="selectedEvent.spaceId"
                                required
                                chips
                              />
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-alert
                              v-if="errorMsg"
                              border="top"
                              color="red"
                              class="ml-5 mr-5"
                            >
                              {{ errorMsg }}
                            </v-alert>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" depressed @click="addEvent()">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                    <v-card v-else min-width="350px" flat :loading="isLoading">
                      <v-toolbar color="transparent" flat dense>
                        <v-spacer></v-spacer>
                        <v-btn
                          v-if="!selectedEvent.type"
                          icon
                          @click="setEdit()"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="!selectedEvent.type"
                          icon
                          @click="deleteEvent()"
                        >
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                        <v-btn icon @click="selectedOpen = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-container>
                        <v-list two-line>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon
                                :color="
                                  selectedEventSpace
                                    ? selectedEventSpace.color
                                    : 'grey'
                                "
                                >mdi-square-rounded</v-icon
                              >
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                selectedEvent.name
                              }}</v-list-item-title>
                              <v-list-item-subtitle>{{
                                getFormattedEventTime(selectedEvent)
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="selectedEvent.description">
                            <v-list-item-avatar>
                              <v-icon>mdi-text</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ selectedEvent.description }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-icon>mdi-view-dashboard</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-chip
                                  dark
                                  :color="
                                    selectedEventSpace
                                      ? selectedEventSpace.color
                                      : 'grey'
                                  "
                                  >{{
                                    selectedEventSpace
                                      ? selectedEventSpace.name
                                      : "None"
                                  }}
                                </v-chip>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-container>
                    </v-card>
                  </v-menu>
                </v-sheet>

                <!-- Dialogs -->
                <v-navigation-drawer
                  v-model="editEventDialog"
                  right
                  app
                  temporary
                  width="420"
                >
                  <v-card flat tile>
                    <v-toolbar flat color="grey lighten-3">
                      <v-toolbar-title>Edit Event</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="resetDialogs">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col offset="1">
                            <v-text-field
                              label="Event Name"
                              v-model="eventToEdit.name"
                              required
                            />
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="1">
                            <v-icon class="mb-1">mdi-view-dashboard</v-icon>
                          </v-col>
                          <v-col>
                            <v-text-field
                              label="Description"
                              v-model="eventToEdit.description"
                              required
                            />
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="1">
                            <v-icon class="mb-1"
                              >mdi-clock-time-four-outline</v-icon
                            >
                          </v-col>
                          <v-col cols="4">
                            <v-menu
                              v-model="dateEditMenu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :value="formattedEditDate"
                                  label="Date"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                color="primary"
                                no-title
                                v-model="eventToEdit.date"
                                @input="dateEditMenu = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col>
                            <v-checkbox
                              v-model="eventToEdit.isAllDay"
                              label="All Day"
                            />
                          </v-col>
                        </v-row>
                        <v-row v-if="!eventToEdit.isAllDay">
                          <v-col cols="4" offset="1">
                            <v-menu
                              ref="startTimeEditMenu"
                              v-model="startTimeEditMenu"
                              :close-on-content-click="false"
                              :return-value.sync="eventToEdit.startTime"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="formattedEditStartTime"
                                  label="Start time"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="startTimeEditMenu"
                                color="primary"
                                v-model="eventToEdit.startTime"
                                full-width
                                no-title
                                @click:minute="
                                  $refs.startTimeEditMenu.save(
                                    eventToEdit.startTime
                                  )
                                "
                              >
                                <v-btn
                                  text
                                  color="primary"
                                  @click="startTimeEditMenu = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    $refs.startTimeEditMenu.save(
                                      eventToEdit.startTime
                                    )
                                  "
                                >
                                  OK
                                </v-btn>
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="1" align="center">
                            <p class="mt-3">-</p>
                          </v-col>
                          <v-col cols="4">
                            <v-menu
                              ref="endTimeEditMenu"
                              v-model="endTimeEditMenu"
                              :close-on-content-click="false"
                              :return-value.sync="eventToEdit.endTime"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="formattedEditEndTime"
                                  label="End time"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="endTimeEditMenu"
                                color="primary"
                                v-model="eventToEdit.endTime"
                                full-width
                                no-title
                                @click:minute="
                                  $refs.endTimeEditMenu.save(
                                    eventToEdit.endTime
                                  )
                                "
                              >
                                <v-btn
                                  text
                                  color="primary"
                                  @click="endTimeEditMenu = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    $refs.endTimeEditMenu.save(
                                      eventToEdit.endTime
                                    )
                                  "
                                >
                                  OK
                                </v-btn>
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="1">
                            <v-icon class="mb-1">mdi-view-dashboard</v-icon>
                          </v-col>
                          <v-col>
                            <v-select
                              :items="spaceDropdown"
                              item-text="name"
                              item-value="id"
                              label="Add a space to organize your event"
                              v-model="eventToEdit.spaceId"
                              required
                              chips
                            >
                              <template v-slot:selection="{ on, attrs, item }">
                                <v-chip
                                  dark
                                  :color="item.color"
                                  v-bind:on="on"
                                  v-bind:attrs="attrs"
                                  >{{ item.name }}</v-chip
                                >
                              </template>
                              <template v-slot:item="{ on, attrs, item }">
                                <v-chip
                                  dark
                                  :color="item.color"
                                  v-bind:on="on"
                                  v-bind:attrs="attrs"
                                  >{{ item.name }}</v-chip
                                >
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-alert
                            v-if="errorMsg"
                            border="top"
                            color="red"
                            class="ml-5 mr-5"
                          >
                            {{ errorMsg }}
                          </v-alert>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn @click="cancelEdit()" depressed color="error"
                        >Cancel</v-btn
                      >
                      <v-btn @click="editEvent()" depressed color="primary"
                        >Save Edit</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-navigation-drawer>

                <v-navigation-drawer
                  v-model="addEventDialog"
                  right
                  app
                  temporary
                  width="420"
                >
                  <v-card flat tile>
                    <v-toolbar flat color="grey lighten-3">
                      <v-toolbar-title>New Event</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="cancelNewEvent">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col offset="1">
                            <v-text-field
                              label="Event Title"
                              v-model="selectedEvent.name"
                              required
                            />
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="1">
                            <v-icon class="mb-1"
                              >mdi-clock-time-four-outline</v-icon
                            >
                          </v-col>
                          <v-col cols="6">
                            <v-menu
                              v-model="dateAddMenuInDrawer"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :value="formattedDate"
                                  label="Date"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                color="primary"
                                no-title
                                v-model="selectedEvent.date"
                                @input="dateAddMenuInDrawer = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col>
                            <v-checkbox
                              label="All Day"
                              v-model="selectedEvent.isAllDay"
                            />
                          </v-col>
                        </v-row>
                        <v-row align="center" v-if="!selectedEvent.isAllDay">
                          <v-col cols="4" offset="1">
                            <v-menu
                              ref="startTimeAddMenu"
                              v-model="startTimeAddMenuInDrawer"
                              :close-on-content-click="false"
                              :return-value.sync="selectedEvent.startTime"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="formattedStartTime"
                                  label="Start time"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="startTimeAddMenuInDrawer"
                                color="primary"
                                v-model="selectedEvent.startTime"
                                full-width
                                no-title
                                @click:minute="
                                  $refs.startTimeAddMenuInDrawer.save(
                                    selectedEvent.startTime
                                  )
                                "
                              >
                                <v-btn
                                  text
                                  color="primary"
                                  @click="startTimeAddMenuInDrawer = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    $refs.startTimeAddMenuInDrawer.save(
                                      selectedEvent.startTime
                                    )
                                  "
                                >
                                  OK
                                </v-btn>
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="1" align="center">
                            <p class="mt-3">-</p>
                          </v-col>
                          <v-col cols="4">
                            <v-menu
                              ref="endTimeAddMenu"
                              v-model="endTimeAddMenuInDrawer"
                              :close-on-content-click="false"
                              :return-value.sync="selectedEvent.endTime"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="formattedEndTime"
                                  label="End time"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="endTimeAddMenuInDrawer"
                                color="primary"
                                v-model="selectedEvent.endTime"
                                full-width
                                no-title
                                @click:minute="
                                  $refs.endTimeAddMenu.save(
                                    selectedEvent.endTime
                                  )
                                "
                              >
                                <v-btn
                                  text
                                  color="primary"
                                  @click="endTimeAddMenu = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    $refs.endTimeAddMenuInDrawer.save(
                                      selectedEvent.endTime
                                    )
                                  "
                                >
                                  OK
                                </v-btn>
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="1">
                            <v-icon class="mb-1">mdi-text</v-icon>
                          </v-col>
                          <v-col>
                            <v-text-field
                              label="Description"
                              v-model="selectedEvent.description"
                              required
                            />
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="1">
                            <v-icon class="mb-1">mdi-view-dashboard</v-icon>
                          </v-col>
                          <v-col>
                            <v-select
                              :items="spaceDropdown"
                              item-text="name"
                              item-value="id"
                              label="Add a space to organize your event"
                              v-model="selectedEvent.spaceId"
                              required
                              chips
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-alert
                            v-if="errorMsg"
                            border="top"
                            color="red"
                            class="ml-5 mr-5"
                          >
                            {{ errorMsg }}
                          </v-alert>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        :loading="isLoading"
                        depressed
                        @click="addEvent"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-navigation-drawer>

                <v-navigation-drawer
                  v-model="linkGoogleCalendarDialog"
                  right
                  app
                  temporary
                  width="420"
                >
                  <v-card flat tile>
                    <v-toolbar flat color="grey lighten-3">
                      <v-toolbar-title>
                        <v-icon left>mdi-link-variant</v-icon>
                        Link Google Calendar
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="linkGoogleCalendarDialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col>
                            <v-card class="rounded-lg" outlined>
                              <v-list-item>
                                <v-list-item-avatar>
                                  <v-img src="../assets/google-calendar.svg" />
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title class="font-weight-bold"
                                    >Google Calendar</v-list-item-title
                                  >
                                  <v-list-item-subtitle>{{
                                    userEmail
                                  }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-card-text>
                                Select which calendars you want to integrate:
                              </v-card-text>
                              <v-card-text>
                                <p>Primary:</p>
                                <v-checkbox
                                  v-model="checkedGoogleCalendarsFromSettings"
                                  :label="googlePrimaryCalendar.name"
                                  :value="googlePrimaryCalendar.id"
                                  multiple
                                  dense
                                ></v-checkbox>
                              </v-card-text>
                              <v-card-text>
                                <p>My Calendars:</p>
                                <v-checkbox
                                  v-for="calendar in googleMyCalendars"
                                  :key="calendar.id"
                                  :value="calendar.id"
                                  :label="calendar.name"
                                  multiple
                                  dense
                                  :color="calendar.color"
                                  v-model="checkedGoogleCalendarsFromSettings"
                                />
                                <p>Other Calendars:</p>
                                <v-checkbox
                                  v-for="calendar in googleOtherCalendars"
                                  :key="calendar.id"
                                  :value="calendar.id"
                                  :label="calendar.name"
                                  multiple
                                  dense
                                  :color="calendar.color"
                                  v-model="checkedGoogleCalendarsFromSettings"
                                />
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-card outlined class="rounded-lg">
                              <v-card-title>Settings</v-card-title>
                              <v-divider />
                              <v-card-text>
                                <!-- <v-switch
                                v-model="linkGoogleSettings.syncEvents"
                                label="Sync Apollo Events to your Google Calendar"
                              /> -->
                                <v-switch
                                  v-model="linkGoogleSettings.greyEvents"
                                  label="Grey out Google Calendar Events"
                                />
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-alert
                            v-if="errorMsg"
                            border="top"
                            color="red"
                            class="ml-5 mr-5"
                          >
                            {{ errorMsg }}
                          </v-alert>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        :loading="isLoading"
                        depressed
                        @click="updateGoogleCalendarSettings"
                      >
                        Save
                      </v-btn>
                      <v-btn
                        v-if="googleCalendarSettings"
                        @click="unlinkGoogleCalendar"
                        depressed
                      >
                        <v-icon left>mdi-link-off</v-icon>
                        Unlink Google Calendar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-navigation-drawer>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <!-- Events Filter Side Navigation bar -->
        <v-navigation-drawer clipped right app v-model="sideNavBar" width="300">
          <v-container>
            <v-toolbar flat v-if="!screenIsMobile">
              <v-btn
                depressed
                dark
                color="black"
                class="ml-2"
                @click="setAddEvent"
              >
                <v-icon>mdi-plus</v-icon>
                New Event
              </v-btn>
            </v-toolbar>
            <v-toolbar flat v-else>
              <v-btn outlined @click="setToday"> Today </v-btn>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined class="ml-1" v-bind="attrs" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                depressed
                dark
                fab
                small
                color="black"
                class="ml-2"
                @click="setAddEvent"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-toolbar>
            <!-- date selector -->
            <v-card outlined class="rounded-lg text-center">
              <v-date-picker
                v-model="focus"
                no-title
                color="primary"
                width="255"
              />
            </v-card>

            <!-- space selector -->
            <v-card class="mt-3 rounded-lg" outlined>
              <v-card-title>Spaces</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-checkbox
                  v-for="(space, i) in $store.state.space.spaces"
                  :key="i"
                  dense
                  ripple
                  multiple
                  :value="space.id"
                  :color="space.color"
                  :label="space.name"
                  v-model="checkedSpaces"
                ></v-checkbox>

                <v-checkbox
                  value="none"
                  label="Other"
                  dense
                  color="grey lighten-1"
                  v-model="checkedSpaces"
                />
              </v-card-text>
            </v-card>

            <v-card
              class="mt-3 rounded-lg"
              outlined
              v-if="googleCalendarEvents.length > 0"
            >
              <v-card-title>Google Calendar</v-card-title>
              <v-divider />
              <v-btn absolute top right icon @click="setLinkGoogleCalendar">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-card-text>
                <v-checkbox
                  v-for="(googleCalendar, i) in filteredGoogleCalendars"
                  :key="i"
                  :value="googleCalendar.id"
                  :label="googleCalendar.name"
                  dense
                  multiple
                  :color="googleCalendar.color"
                  v-model="checkedGoogleCalendarsToShow"
                />
              </v-card-text>
            </v-card>

            <v-btn
              class="mt-3"
              depressed
              @click="setLinkGoogleCalendar"
              :loading="isLoading"
              :disabled="googleCalendarEvents.length > 0"
            >
              <v-icon left>mdi-link</v-icon>
              {{
                googleCalendarEvents.length > 0
                  ? "Linked Google Calendar"
                  : "Link Google Calendar"
              }}
            </v-btn>
          </v-container>
        </v-navigation-drawer>
      </v-row>
      <v-snackbar timeout="1500" v-model="savingSnackbar">
        Saving...
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="savingSnackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar timeout="1500" v-model="successSnackbar">
        {{ successMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="successSnackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar timeout="1500" v-model="errorSnackbar">
        {{ errorMsg }}
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="errorSnackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </template>
  </PageLayout>
</template>

<script>
import moment from "moment";
import PageLayout from "../layouts/PageLayout.vue";
import { mapState } from "vuex";
import { db } from "../firebase/firebase";

export default {
  name: "Calendar",
  components: {
    PageLayout,
  },
  data() {
    return {
      //Calendar Variables and Props
      focus: "",
      type: "week",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
      },
      value: "",
      ready: false,
      selectedEvent: {},
      eventToEdit: {},
      selectedElement: null,
      selectedOpen: false,
      isLoading: false,
      errorMsg: null,
      checkedSpaces: [],

      //google calendar variables and props
      allGoogleCalendars: [],
      googlePrimaryCalendar: {},
      googleMyCalendars: [],
      googleOtherCalendars: [],
      linkGoogleCalendarDialog: false,
      googleCalendarEvents: [],
      checkedGoogleCalendarsFromSettings: [],
      checkedGoogleCalendarsToShow: [],

      linkGoogleSettings: {
        syncEvents: false,
        greyEvents: false,
      },

      // draggable variables
      createStart: null,
      createEvent: null,
      dragEvent: null,
      dragTime: null,
      originalDragEvent: null,

      // Dialogs, Side Bars
      sideNavBar: false,
      addEventDialog: false,
      editEventDialog: false,
      savingSnackbar: false,
      successSnackbar: false,
      successMessage: "",
      errorSnackbar: false,

      // Date-Time Menus
      dateEditMenu: false,
      startTimeEditMenu: false,
      endTimeEditMenu: false,
      dateAddMenuInDrawer: false,
      dateAddMenuInDialog: false,
      startTimeAddMenuInDialog: false,
      startTimeAddMenuInDrawer: false,
      endTimeAddMenuInDialog: false,
      endTimeAddMenuInDrawer: false,
      isAllDayEvent: false,

      // Dialog Props
      textFieldProps: {
        prependIcon: "mdi-calendar",
      },
      dateProps: {
        color: "primary",
      },
      timeProps: {
        color: "primary",
        appendIcon: "mdi-event",
      },
    };
  },
  async mounted() {
    this.ready = true;
    this.updateTime();
    this.checkAllSpaces();
    await this.getGoogleCalendars();
    await this.getGoogleCalendarEvents();
  },
  computed: {
    screenIsMobile() {
      return this.$vuetify.breakpoint.width < 960;
    },
    events() {
      return this.$store.state.event.events.concat(this.googleCalendarEvents);
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    spaceDropdown() {
      return [...this.$store.state.space.spaces, { name: "None", id: "none" }];
    },
    selectedEventSpace() {
      if (this.selectedEvent.type === "google") {
        let googleSpace = this.filteredGoogleCalendars.find(
          (cal) => cal.id === this.selectedEvent.spaceId
        );
        return googleSpace;
      }
      const space = this.$store.state.space.spaces.find(
        (space) => space.id === this.selectedEvent.spaceId
      );
      return space ? space : null;
    },
    // formatted times
    // formattedStart() {
    //   return moment(this.selectedEvent.start).format("h:mm a");
    // },
    // formattedEnd() {
    //   return moment(this.selectedEvent.end).format("h:mm a");
    // },
    formattedDate() {
      return moment(this.selectedEvent.date).format("ddd, MMMM Do");
    },
    formattedStartTime() {
      return moment(this.selectedEvent.startTime, "h:mm a").format("h:mm a");
    },
    formattedEndTime() {
      return moment(this.selectedEvent.endTime, "h:mm a").format("h:mm a");
    },
    formattedEditStartTime() {
      return moment(this.eventToEdit.startTime, "h:mm a").format("h:mm a");
    },
    formattedEditEndTime() {
      return moment(this.eventToEdit.endTime, "h:mm a").format("h:mm a");
    },
    formattedEditDate() {
      return moment(this.eventToEdit.date).format("ddd, MMMM Do");
    },
    filteredEvents() {
      return this.events.filter(
        (event) =>
          this.checkedSpaces.includes(event.spaceId) ||
          this.checkedGoogleCalendarsToShow.includes(event.spaceId) ||
          event.spaceId === ""
      );
    },
    filteredGoogleCalendars() {
      if (!this.googleCalendarSettings) {
        return [];
      }
      return this.allGoogleCalendars.filter((calendar) =>
        this.googleCalendarSettings.calendarIds.includes(calendar.id)
      );
    },
    ...mapState({
      userId: (state) => state.user.id,
      username: (state) => state.user.name,
      userEmail: (state) => state.user.email,
      userPhoto: (state) => state.user.photoURL,
      googleCalendarSettings: (state) => state.setting.settings,
    }),
  },
  methods: {
    async addEvent() {
      this.isLoading = true;
      this.savingSnackbar = true;
      try {
        this.selectedOpen = false;
        let start = "";
        let end = "";
        if (this.selectedEvent.isAllDay) {
          start = moment(this.selectedEvent.date).format("YYYY-MM-DD");
          end = moment(this.selectedEvent.date).format("YYYY-MM-DD");
        } else {
          start = moment(
            this.selectedEvent.date + " " + this.selectedEvent.startTime
          ).format("YYYY-MM-DD HH:mm");
          end = moment(
            this.selectedEvent.date + " " + this.selectedEvent.endTime
          ).format("YYYY-MM-DD HH:mm");
        }

        const event = {
          name: this.selectedEvent.name
            ? this.selectedEvent.name
            : "(no title)",
          description: this.selectedEvent.description,
          spaceId: this.selectedEvent.spaceId
            ? this.selectedEvent.spaceId
            : "none",
          start: start,
          end: end,
          isAllDay: this.selectedEvent.isAllDay,
        };

        const snapshot = await db
          .collection("users")
          .doc(this.userId)
          .collection("events")
          .add(event);
        event.id = snapshot.id;

        if (this.createEvent) {
          this.$store.dispatch("event/addEventByClick", event);
          this.checkAllSpaces();
        } else {
          this.$store.dispatch("event/addEvent", event);
        }

        this.savingSnackbar = false;
        this.successMessage = "Event added successfully";
        this.successSnackbar = true;

        this.resetDialogs();
      } catch (error) {
        this.errorMsg = "An unexpected error has occurred!";
        if (error.message) {
          this.errorMsg = error.message;
        }
        this.savingSnackbar = false;
        this.errorSnackbar = true;
      }
      this.isLoading = false;
      this.createEvent = null;
    },
    setAddEvent() {
      this.selectedEvent = {
        name: "",
        description: "",
        spaceId: "none",
        date:
          new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getDate(),
        startTime: new Date().getHours() + 1 + ":00",
        endTime: new Date().getHours() + 2 + ":00",
      };
      this.addEventDialog = true;
    },
    setEdit() {
      this.selectedOpen = false;
      if (this.selectedEvent.isAllDay) {
        this.isAllDayEvent = true;

        this.eventToEdit = {
          id: this.selectedEvent.id,
          name: this.selectedEvent.name,
          description: this.selectedEvent.description
            ? this.selectedEvent.description
            : "",
          spaceId: this.selectedEvent.spaceId,
          date: moment(this.selectedEvent.start).format("YYYY-MM-DD"),
          startTime: new Date().getHours() + ":00",
          endTime: new Date().getHours() + 1 + ":00",
          isAllDay: this.selectedEvent.isAllDay,
        };
      } else {
        this.isAllDayEvent = false;

        this.eventToEdit = {
          id: this.selectedEvent.id,
          name: this.selectedEvent.name,
          description: this.selectedEvent.description
            ? this.selectedEvent.description
            : "",
          spaceId: this.selectedEvent.spaceId,
          date: moment(this.selectedEvent.start).format("YYYY-MM-DD"),
          startTime: new Date(this.selectedEvent.start).getHours() + ":00",
          endTime: new Date(this.selectedEvent.end).getHours() + ":00",
          isAllDay: this.selectedEvent.isAllDay,
        };
      }

      this.editEventDialog = true;
    },
    async editEvent() {
      this.isLoading = true;
      this.savingSnackbar = true;
      try {
        let start = "";
        let end = "";
        if (this.eventToEdit.isAllDay) {
          start = moment(this.eventToEdit.date).format("YYYY-MM-DD");
          end = moment(this.eventToEdit.date).format("YYYY-MM-DD");
        } else {
          start = moment(
            this.eventToEdit.date + " " + this.eventToEdit.startTime
          ).format("YYYY-MM-DD HH:mm");
          end = moment(
            this.eventToEdit.date + " " + this.eventToEdit.endTime
          ).format("YYYY-MM-DD HH:mm");
        }

        const event = {
          id: this.selectedEvent.id,
          name: this.eventToEdit.name,
          description: this.eventToEdit.description,
          spaceId: this.eventToEdit.spaceId,
          start: start,
          end: end,
          isAllDay: this.eventToEdit.isAllDay,
        };

        this.savingSnackbar = false;
        this.successMessage = "Event edited successfully";
        this.successSnackbar = true;

        await db
          .collection("users")
          .doc(this.userId)
          .collection("events")
          .doc(event.id)
          .set(event);
        this.$store.dispatch("event/editEvent", event);

        this.resetDialogs();
      } catch (error) {
        this.errorMsg = "An unexpected error has occurred!";
        if (error.message) {
          this.errorMsg = error.message;
        }
        this.savingSnackbar = false;
        this.errorSnackbar = true;
      }
      this.isLoading = false;
    },
    cancelEdit() {
      this.resetDialogs();
    },
    async deleteEvent() {
      this.isLoading = true;
      try {
        await db
          .collection("users")
          .doc(this.userId)
          .collection("events")
          .doc(this.selectedEvent.id)
          .delete();
        this.$store.dispatch("event/deleteEvent", this.selectedEvent.id);
        this.resetDialogs();
      } catch (error) {
        this.errorMsg = error.message;
      }
      this.isLoading = false;
    },
    getFormattedEventTime(event) {
      if (event.isAllDay) {
        if (event.start !== event.end) {
          return (
            moment(event.start).format("MMM DD") +
            " - " +
            moment(event.end).format("MMM DD, YYYY")
          );
        }
        return moment(event.date).format("MMM DD, YYYY");
      } else {
        return (
          moment(event.start).format("MMM DD, YYYY") +
          " " +
          moment(event.start).format("h:mm a") +
          " - " +
          moment(event.end).format("h:mm a")
        );
      }
    },
    checkAllSpaces() {
      this.checkedSpaces[0] = "none";
      for (let i = 0; i < this.$store.state.space.spaces.length; i++) {
        this.checkedSpaces.push(this.$store.state.space.spaces[i].id);
      }

      if (this.googleCalendarSettings) {
        this.checkedGoogleCalendarsToShow = [
          ...this.googleCalendarSettings.calendarIds,
        ];
      }
    },

    resetDialogs() {
      this.isAllDayEvent = false;
      this.selectedOpen = false;
      this.createEvent = null;
      this.errorMsg = null;
      this.addEventDialog = false;
      this.editEventDialog = false;
      this.selectedEvent = {};
    },
    saveStartTime() {
      this.$refs.startTimeEditMenu.save(this.eventToEdit.startTime);
      this.formatStartTime = moment(
        this.selectedEvent.startTime,
        "h:mm a"
      ).format("h:mm a");
    },
    saveEndTime() {
      this.$refs.endTimeEditMenu.save(this.eventToEdit.endTime);
      this.formatEndTime = moment(this.selectedEvent.endTime, "h:mm a").format(
        "h:mm a"
      );
    },

    // Calendar methods
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    getEventColor(event) {
      if (event.spaceId === "") {
        return "primary";
      } else if (event.type === "google") {
        if (!this.googleCalendarSettings) return;
        if (this.googleCalendarSettings.greyEvents == true) {
          return "grey lighten-1";
        }
        return event.color;
      } else if (event.spaceId === "none") {
        return "grey lighten-1";
      } else {
        let found = this.$store.state.space.spaces.filter(
          (space) => space.id === event.spaceId
        )[0];
        return found ? found.color : "grey lighten-1";
      }
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedEvent.date = moment(event.start).format("YYYY-MM-DD");
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    // dragging functionality
    mouseDownEvent({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.originalDragEvent = { ...event };
      }
    },

    mouseDownTime(time) {
      const mouseTime = this.toTime(time);
      if (this.dragEvent && this.dragTime === null) {
        const start = new Date(this.dragEvent.start).getTime();
        this.dragTime = mouseTime - start;
      }
      // create a new event if we aren't dragging a current event and the menu is not open
      if (!this.dragEvent && this.selectedOpen == false) {
        const mouse = this.toTime(time);
        this.createStart = this.roundTime(mouse, true, 30);
        this.createEvent = {
          name: "(no title)",
          description: "",
          spaceId: "",
          start: moment(this.createStart).format("YYYY-MM-DD HH:mm"),
          end: moment(this.createStart)
            .add(1, "hours")
            .format("YYYY-MM-DD HH:mm"),
        };
        this.$store.dispatch("event/addEvent", this.createEvent);
      }
    },
    mouseUpTime({ nativeEvent }) {
      if (this.createEvent) {
        // just finished dragging to create a new event
        const open = () => {
          this.selectedEvent = {
            ...this.createEvent,
            name: "",
            date: moment(this.createStart).format("YYYY-MM-DD"),
            startTime: moment(this.createEvent.start).format("HH:mm"),
            endTime: moment(this.createEvent.end).format("HH:mm"),
            isAllDay: false,
          };
          if (this.screenIsMobile) {
            this.addEventDialog = true;
          } else {
            this.selectedElement = nativeEvent.target;
            requestAnimationFrame(() =>
              requestAnimationFrame(() => (this.selectedOpen = true))
            );
          }
        };

        if (this.selectedOpen) {
          this.cancelNewEvent();
        } else {
          open();
        }

        nativeEvent.stopPropagation();
      }

      if (this.dragEvent && this.dragTime) {
        if (this.dragEvent.type == "google") return;
        if (!this.createEvent) {
          if (
            this.originalDragEvent.start !== this.dragEvent.start &&
            this.originalDragEvent.end !== this.dragEvent.end
          ) {
            this.isLoading = true;
            this.savingSnackbar = true;
            try {
              // we are writing to the data base and set the things to null for a split second
              this.$store.dispatch("event/editEvent", this.dragEvent);
              db.collection("users")
                .doc(this.userId)
                .collection("events")
                .doc(this.dragEvent.id)
                .set(this.dragEvent);

              this.savingSnackbar = false;
              this.successMessage = "Event successfully saved!";
              this.successSnackbar = true;
            } catch (error) {
              this.errorMsg = error.message;
              this.savingSnackbar = false;
              this.errorSnackbar = true;
            }
            this.isLoading = false;
          }
        } else {
          // console.log(
          //   "TODO: make an event that is currently being created draggable"
          // );
        }
      }
      this.dragTime = null;
      this.dragEvent = null;
      this.createStart = null;
    },
    mouseMoveOnCalendar(tms) {
      const currentMouseTime = this.toTime(tms);
      // if we have a drag event and drag time AKA we clicked on an
      // event and in the process of dragging it
      if (this.dragEvent && this.dragTime) {
        if (this.dragEvent.type == "google") {
          return;
        }
        // 1. grab the current events duration
        const startTime = new Date(this.dragEvent.start).getTime();
        const endTime = new Date(this.dragEvent.end).getTime();
        const eventDuration = endTime - startTime; // how long the event is

        // 2. create a new start time rounded to 15 minutes and calculate a new end time based on the events duration
        const newRoundedStartTime = this.roundTime(
          currentMouseTime - this.dragTime
        ); // the change in time from start of click to current mouse position
        const newRoundedEndTime = newRoundedStartTime + eventDuration;

        // 3. update the drag event and format to a string
        this.dragEvent.start =
          moment(newRoundedStartTime).format("YYYY-MM-DD HH:mm");
        this.dragEvent.end =
          moment(newRoundedEndTime).format("YYYY-MM-DD HH:mm");
      }
    },
    mouseLeaveArea() {
      if (!this.createEvent) {
        this.createEvent = null;
        this.createStart = null;
        this.dragTime = null;
        this.dragEvent = null;
      }
    },
    cancelNewEvent() {
      const i = this.events.length - 1;
      if (i !== -1) {
        this.$store.dispatch("event/deleteEvent", this.events[i]);
      }
      this.resetDialogs();
    },
    roundTime(time, down = true, roundTo = 15) {
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    async setLinkGoogleCalendar() {
      this.isLoading = true;
      try {
        await this.getGoogleCalendars();
      } catch (error) {
        this.errorMsg = error.message;
      }
      if (this.googleCalendarSettings) {
        this.checkedGoogleCalendarsFromSettings = [
          ...this.googleCalendarSettings.calendarIds,
        ];
        this.greyEvents = this.googleCalendarSettings.greyEvents;
        this.syncEvents = this.googleCalendarSettings.syncEvents;
      } else {
        this.checkedGoogleCalendarsFromSettings.push(this.userEmail);
      }

      this.isLoading = false;
      this.linkGoogleCalendarDialog = true;
    },
    async updateGoogleCalendarSettings() {
      this.isLoading = true;
      this.savingSnackbar = true;
      try {
        const docRef = db.collection("settings").doc(this.userId);
        const googleCalendarSettings = {
          calendarIds: this.checkedGoogleCalendarsFromSettings,
          greyEvents: this.linkGoogleSettings.greyEvents,
          syncEvents: this.linkGoogleSettings.syncEvents,
        };
        await docRef.set(googleCalendarSettings);
        await this.getGoogleCalendarEvents();

        this.savingSnackbar = false;
        this.successMessage = "Settings successfully saved!";
        this.successSnackbar = true;
      } catch (error) {
        this.errorMsg = error.message;
        this.savingSnackbar = false;
        this.errorSnackbar = true;
      }
      this.checkAllSpaces();
      this.linkGoogleCalendarDialog = false;
      this.isLoading = false;
    },
    async getGoogleCalendars() {
      const googleCalendars = await this.$gapi.getGapiClient().then((gapi) => {
        return gapi.client.calendar.calendarList.list();
      });

      let calendars = googleCalendars.result.items.map((calendar) => {
        return {
          id: calendar.id,
          name: calendar.summary,
          color: calendar.backgroundColor,
          accessRole: calendar.accessRole,
        };
      });

      this.googlePrimaryCalendar = calendars.find((calendar) => {
        return calendar.id === this.userEmail;
      });

      this.googleMyCalendars = calendars.filter((calendar) => {
        return (
          calendar.accessRole === "owner" && calendar.id !== this.userEmail
        );
      });

      this.googleOtherCalendars = calendars.filter((calendar) => {
        return calendar.accessRole !== "owner";
      });

      this.allGoogleCalendars = calendars;
    },
    async getGoogleCalendarEvents() {
      this.isLoading = true;
      try {
        let googleCalendarEvents = [];
        if (this.googleCalendarSettings === null) {
          this.isLoading = false;
          return googleCalendarEvents;
        }

        for (
          let i = 0;
          i < this.googleCalendarSettings.calendarIds.length;
          i++
        ) {
          const calendarId = this.googleCalendarSettings.calendarIds[i];
          const calendarEvents = await this.$gapi
            .getGapiClient()
            .then((gapi) => {
              return gapi.client.calendar.events.list({
                calendarId,
                timeMin: new Date().toISOString(),
                maxResults: 10,
                showDeleted: false,
                singleEvents: true,
                orderBy: "startTime",
              });
            });

          calendarEvents.result.items.forEach((event) => {
            event.calendarId = calendarId;
          });

          googleCalendarEvents = [
            ...googleCalendarEvents,
            ...calendarEvents.result.items,
          ];
        }

        const events = googleCalendarEvents.map((event) => {
          let eventStart = "";
          let isAllDay = true;
          if (event.start.dateTime) {
            eventStart = moment(event.start.dateTime).format(
              "YYYY-MM-DD HH:mm"
            );
            isAllDay = false;
          } else {
            eventStart = moment(event.start.date).format("YYYY-MM-DD");
          }

          const eventEnd = event.end.dateTime
            ? moment(event.end.dateTime).format("YYYY-MM-DD HH:mm")
            : moment(event.end.date).subtract(1, "days").format("YYYY-MM-DD");

          const calendar = this.allGoogleCalendars.find((calendar) => {
            return calendar.id == event.calendarId;
          });

          const color = calendar.color;
          const calendarId = calendar.id;

          return {
            name: event.summary,
            color: color,
            description: event.description,
            spaceId: calendarId,
            type: "google",
            start: eventStart,
            end: eventEnd,
            isAllDay: isAllDay,
          };
        });
        this.googleCalendarEvents = events;
      } catch (error) {
        this.errorMsg = error.message;
      }
      this.isLoading = false;
    },
    async unlinkGoogleCalendar() {
      this.isLoading = true;
      this.savingSnackbar = true;
      try {
        const docRef = db.collection("settings").doc(this.userId);
        await docRef.delete();
        this.googleCalendarEvents = [];
        this.savingSnackbar = false;
        this.successMessage = "Google calendar successfully unlinked!";
      } catch (error) {
        this.errorMsg = error.message;
        this.savingSnackbar = false;
        this.errorSnackbar = true;
      }
      this.isLoading = false;
      this.linkGoogleCalendarDialog = false;
    },
  },
};
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>