import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAXR2fbgWOEJJT9L5tinCRkfdIQFLY_uW4",
  authDomain: "scheduler-1e2fa.firebaseapp.com",
  projectId: "scheduler-1e2fa",
  storageBucket: "scheduler-1e2fa.appspot.com",
  messagingSenderId: "633947240556",
  appId: "1:633947240556:web:72580c6a5e668ca8a8c049",
  measurementId: "G-NYMG7X2KC7",
};

firebase.initializeApp(firebaseConfig);

export const firebaseAuth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();