import { firestoreAction } from "vuexfire";
import { db } from "../../firebase/firebase";

const namespaced = true;

const state = {
    settings: {},
};

const actions = {
    bindSettingsRef: firestoreAction((context) => {
        return context.bindFirestoreRef(
            "settings",
            db.collection("settings").doc(context.rootState.user.id)
        );
    }),
    unbindSettingsRef: firestoreAction((context) => {
        return context.unbindFirestoreRef("settings").doc(context.rootState.user.id);
    }),
};

export default {
    namespaced,
    state,
    actions,
};