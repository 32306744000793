<template>
  <PageLayout>
    <template v-slot:header>
      <PageHeader v-if="screenIsMobile" :title="goal.name" class="text-truncate" />
      <PageHeader v-else :title="goal.name" :breadcrumbs="breadcrumbs" />
    </template>
    <template v-slot:body>
      <v-row class="my-0">
        <v-col cols="12" md="6">
          <v-card class="rounded-lg" flat>
            <v-card-title class="text-h6 font-weight-bold">About</v-card-title>
            <v-btn icon absolute top right @click="setEditGoal">
              <v-icon color="grey">mdi-pencil</v-icon>
            </v-btn>
            <v-navigation-drawer
              v-model="editGoalDialog"
              right
              app
              temporary
              width="420"
            >
              <v-card flat tile>
                <v-toolbar flat color="grey lighten-3">
                  <v-toolbar-title>Edit Goal</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="editGoalDialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-row>
                          <v-col offset="1">
                            <v-text-field
                              label="Goal Title"
                              v-model="goalToEdit.name"
                              required
                            />
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="1">
                            <v-icon class="mb-1">mdi-text</v-icon>
                          </v-col>
                          <v-col>
                            <v-text-field
                              label="Description"
                              v-model="goalToEdit.description"
                              required
                            />
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="1">
                            <v-icon class="mb-1">mdi-view-dashboard</v-icon>
                          </v-col>
                          <v-col>
                            <v-chip-group
                              v-model="goalToEdit.spaceId"
                              active-class="grey darken-2 white--text"
                              column
                              mandatory
                            >
                              <v-chip
                                v-for="space in $store.state.space.spaces"
                                :color="space.color"
                                dark
                                :key="space.id"
                                :value="space.id"
                              >
                                <v-icon left>{{ space.icon }}</v-icon>
                                {{ space.name }}
                              </v-chip>
                            </v-chip-group>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" align="center">
                        <v-card
                          max-width="400"
                          flat
                          outlined
                          class="rounded-xl"
                        >
                          <v-card-text>
                            <v-date-picker
                              v-model="goalToEdit.completionDate"
                              color="primary"
                              no-title
                            />
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-alert
                        v-if="errorMsg"
                        border="top"
                        color="red"
                        class="ml-5 mr-5"
                      >
                        {{ errorMsg }}
                      </v-alert>
                    </v-row>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        @click="editGoal"
                        :disabled="!editGoalIsValid"
                        :loading="isLoading"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-navigation-drawer>
            <v-card-text>
              <v-row align="center">
                <v-col cols="1" align="center">
                  <v-icon>mdi-account</v-icon>
                </v-col>
                <v-col cols="11" md="8">
                  <v-text-field
                    placeholder="title"
                    v-model="goal.name"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="1">
                  <v-icon>mdi-text</v-icon>
                </v-col>
                <v-col cols="11" md="8">
                  <v-text-field
                    placeholder="description"
                    v-model="goal.description"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="1">
                  <v-icon>mdi-view-dashboard</v-icon>
                </v-col>
                <v-col cols="11" md="8">
                  <v-text-field
                    :placeholder="space.name"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col>
              <v-card class="rounded-lg" flat>
                <v-card-title>Progress</v-card-title>
                <v-row>
                  <v-col align="center">
                    <v-progress-circular
                      :value="progress"
                      :color="space.color"
                      size="100"
                      width="8"
                      >{{ progress }}%</v-progress-circular
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card class="rounded-lg" flat>
                <v-card-title> Motivations </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="7" md="6" lg="5">
                      <v-chip-group column>
                        <v-chip
                          v-for="(motivator, i) in goalMotivators"
                          :key="i"
                          dark
                          :color="space.color"
                          close
                          @click:close="deleteMotivator(motivator)"
                        >
                          {{ motivator.name }}
                        </v-chip>
                        <v-chip
                          v-if="!editMotivators"
                          @click="editMotivators = true"
                        >
                          <v-avatar left>
                            <v-icon>mdi-plus</v-icon>
                          </v-avatar>
                          Add
                        </v-chip>
                        <v-chip v-else>
                          <v-text-field
                            :color="space.color"
                            @keydown.enter="addMotivator()"
                            v-model="selectedMotivator"
                            prepend-inner-icon="mdi-plus"
                          ></v-text-field>
                        </v-chip>
                        <v-chip
                          v-if="editMotivators"
                          color="success"
                          @click="resetAddMotivators()"
                        >
                          Done
                          <v-icon right>mdi-check</v-icon>
                        </v-chip>
                      </v-chip-group>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Milestones Section -->
      <v-row>
        <v-col>
          <v-card class="rounded-lg" flat>
            <v-card-title class="text-h6 font-weight-bold"
              >Milestones</v-card-title
            >
            <v-btn
              absolute
              top
              right
              dark
              @click="milestoneDialog = !milestoneDialog"
            >
              <v-icon>mdi-plus</v-icon>add
            </v-btn>
            <v-navigation-drawer
              v-model="milestoneDialog"
              right
              app
              temporary
              width="420"
            >
              <v-card flat tile>
                <v-toolbar flat color="grey lighten-3">
                  <v-toolbar-title>New Milestone</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="milestoneDialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col offset="1">
                        <v-text-field
                          label="Milestone Title"
                          v-model="newMilestone.name"
                          required
                        />
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="1">
                        <v-icon class="mb-1">mdi-view-dashboard</v-icon>
                      </v-col>
                      <v-col>
                        <v-btn-toggle
                          v-model="newMilestone.milestoneType"
                          color="primary"
                          group
                        >
                          <v-btn value="number">Number</v-btn>
                          <v-btn value="true-false">True/False</v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row v-if="newMilestone.milestoneType === 'number'">
                      <v-col offset="1" cols="4">
                        <v-text-field
                          label="Start"
                          v-model="newMilestone.numCompleted"
                          type="number"
                          required
                        />
                      </v-col>
                      <v-col offset="1" cols="4">
                        <v-text-field
                          label="Target"
                          v-model="newMilestone.numToComplete"
                          type="number"
                          required
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn
                          color="primary"
                          @click="addMilestone"
                          :disabled="!isValid"
                          >Save</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-navigation-drawer>
            <v-card-text>
              <v-container>
                <v-row class="mb-4">
                  <v-toolbar class="rounded-lg" color="grey lighten-4" flat>
                    <span class="subtitle">Name</span>
                    <v-spacer></v-spacer>
                    <span class="subtitle">Status</span>
                    <v-spacer></v-spacer>
                    <span class="subtitle">Actions</span>
                  </v-toolbar>
                </v-row>
                <v-row v-for="milestone in milestones" :key="milestone.id">
                  <v-col cols="3">{{ milestone.name }}</v-col>
                  <v-col cols="6" class="d-flex">
                    <v-row align="center">
                      <v-col cols="10">
                        <v-progress-linear
                          rounded
                          :color="space.color"
                          height="7"
                          :value="getMilestoneProgress(milestone)"
                        ></v-progress-linear>
                      </v-col>
                      <v-col>
                        <div>
                          {{ milestone.numCompleted }} /
                          {{ milestone.numToComplete }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="3" align="end">
                    <v-btn
                      color="black"
                      dark
                      icon
                      small
                      @click="decrementMilestone(milestone)"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <v-btn
                      color="black"
                      dark
                      icon
                      small
                      @click="incrementMilestone(milestone)"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                      color="black"
                      dark
                      icon
                      small
                      @click="completeMilestone(milestone)"
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-btn
                      color="black"
                      dark
                      icon
                      small
                      @click="deleteMilestone(milestone)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Tasks Section -->
      <v-row>
        <v-col>
          <v-card flat class="mt-5 rounded-lg">
            <v-card-title class="text-h6 font-weight-bold">Tasks</v-card-title>
            <v-card-text>
              <p v-if="tasks.length === 0">Add some tasks to begin</p>
              <v-list v-else>
                <v-list-item v-for="(task, i) in tasks" :key="i">
                  <v-checkbox
                    @click="completeTask(task)"
                    v-model="task.isComplete"
                    :color="space.color"
                  >
                    <template v-slot:label>
                      <div
                        :class="
                          (task.isComplete &&
                            'grey--text text-decoration-line-through') ||
                          'text--primary'
                        "
                        class="ml-3"
                        v-text="task.name"
                      ></div>
                    </template>
                  </v-checkbox>
                  <v-spacer></v-spacer>
                  <v-list-item-icon class="mr-3">
                    <v-btn icon @click="deleteTask(task)">
                      <v-icon color="grey lighten-1">mdi-trash-can</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
              <v-text-field
                v-model="taskName"
                label="Add a new task"
                prepend-inner-icon="mdi-plus"
                filled
                @keydown.enter="addTask()"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Button Row -->
      <v-row>
        <v-col align="right">
          <v-btn
            v-if="goal.isComplete"
            @click="uncompleteGoal"
            dark
            class="mr-2"
          >
            <v-icon left>mdi-undo</v-icon>
            undo
          </v-btn>
          <v-btn
            class="mr-2"
            color="success"
            @click="completeGoal"
            :disabled="goal.isComplete"
          >
            <v-icon left>mdi-check-circle</v-icon>
            Complete
          </v-btn>
          <v-btn color="error" :loading="isLoading" @click="deleteGoal">
            Delete
          </v-btn>
        </v-col>
      </v-row>

      <!-- Snackbars -->
      <v-snackbar v-model="savingSnackbar">
        Saving...
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="savingSnackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="successSnackbar">
        {{ successMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="successSnackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="errorSnackbar">
        Error encountered while saving milestone
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="errorSnackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </template>
  </PageLayout>
</template>

<script>
import PageLayout from "../layouts/PageLayout";
import PageHeader from "../components/PageHeader";
import { db } from "../firebase/firebase";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "Goal",
  components: {
    PageLayout,
    PageHeader,
  },
  data() {
    return {
      // snackbar
      savingSnackbar: false,
      successSnackbar: false,
      successMessage: "",
      errorSnackbar: false,

      // dialog
      milestoneDialog: false,
      editGoalDialog: false,
      goalToEdit: {
        name: "",
        spaceId: "",
        completionDate: "",
        description: "",
      },
      errorMsg: "",

      // milestones
      milestones: [],
      newMilestone: {
        name: "",
        milestoneType: "number",
        description: "",
        numCompleted: 0,
        numToComplete: 0,
      },

      // other
      editMotivators: false,
      selectedMotivator: "",
      isLoading: false,
      taskName: "",
    };
  },
  async created() {
    await db
      .collection("users")
      .doc(this.userId)
      .collection("goals")
      .doc(this.goal.id)
      .collection("milestones")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.milestones.push({ ...doc.data(), id: doc.id });
        });
      });
  },
  computed: {
    screenIsMobile() {
      return this.$vuetify.breakpoint.width < 960;
    },
    isValid() {
      if (this.newMilestone.milestoneType === "number") {
        return (
          this.newMilestone.name.length > 0 &&
          this.newMilestone.numCompleted >= 0 &&
          this.newMilestone.numToComplete > 0
        );
      } else {
        return this.newMilestone.name.length > 0;
      }
    },
    editGoalIsValid() {
      return this.goalToEdit.name.length > 0;
    },
    goal() {
      return this.$store.state.goal.goals.find(
        (goal) => goal.id === this.$route.params.id
      );
    },
    space() {
      return this.$store.state.space.spaces.find(
        (space) => space.id === this.goal.spaceId
      );
    },
    tasks() {
      return this.$store.getters["task/tasksByGoal"](this.$route.params.id);
    },

    goalMotivators() {
      return this.$store.state.motivator.motivators.filter(
        (motivator) => motivator.goalId === this.goal.id
      );
    },
    breadcrumbs() {
      let breadcrumbs = [];
      breadcrumbs.push(
        { icon: "icon", text: "", href: "/" },
        { text: "Overview", href: "/overview" },
        { text: `${this.goal.name}`, href: "" }
      );
      return breadcrumbs;
    },
    progress() {
      const progress = Math.round(
        (this.goal.numCompleted / this.goal.numToComplete) * 100
      );
      if (Number.isNaN(progress)) {
        return 0;
      } else {
        return progress;
      }
    },
    ...mapState({
      userId: (state) => state.user.id,
    }),
  },
  methods: {
    setEditGoal() {
      this.goalToEdit = { ...this.goal };
      this.editGoalDialog = true;
    },
    getMilestoneProgress(milestone) {
      return (milestone.numCompleted / milestone.numToComplete) * 100;
    },
    async addMilestone() {
      this.milestoneDialog = false;
      this.savingSnackbar = true;
      try {
        const goalRef = db
          .collection("users")
          .doc(this.userId)
          .collection("goals")
          .doc(this.goal.id);

        const milestone = {
          name: this.newMilestone.name,
          milestoneType: this.newMilestone.milestoneType,
          numCompleted: parseInt(
            this.newMilestone.milestoneType === "number"
              ? this.newMilestone.numCompleted
              : 0
          ),
          numToComplete: parseInt(
            this.newMilestone.milestoneType === "number"
              ? this.newMilestone.numToComplete
              : 1
          ),
        };

        const batch = db.batch();

        let newNumCompleted =
          parseInt(this.goal.numCompleted) + milestone.numCompleted;
        let newNumToComplete =
          parseInt(this.goal.numToComplete) + milestone.numToComplete;

        batch.update(goalRef, {
          numCompleted: newNumCompleted,
          numToComplete: newNumToComplete,
        });

        const milestoneRef = goalRef.collection("milestones").doc();
        batch.set(milestoneRef, milestone);

        batch
          .commit()
          .then(() => {
            this.milestones.push({ ...milestone, id: milestoneRef.id });
            this.savingSnackbar = false;
            this.successMessage = "Milestone added";
            this.successSnackbar = true;
          })
          .catch((error) => {
            this.errorMsg = error.message;
            this.savingSnackbar = false;
            this.errorSnackbar = true;
          });
      } catch (error) {
        this.errorMsg = error.message;
        this.savingSnackbar = false;
        this.errorSnackbar = true;
      }
      this.resetState();
    },
    async completeMilestone(milestone) {
      if (milestone.numCompleted < milestone.numToComplete) {
        this.savingSnackbar = true;
        try {
          const goalRef = db
            .collection("users")
            .doc(this.userId)
            .collection("goals")
            .doc(this.goal.id);

          const batch = db.batch();

          const amountAdded = milestone.numToComplete - milestone.numCompleted;
          milestone.numCompleted += amountAdded;
          const newNumCompleted =
            parseInt(this.goal.numCompleted) + amountAdded;

          batch.update(goalRef, {
            numCompleted: newNumCompleted,
          });

          const milestoneRef = goalRef
            .collection("milestones")
            .doc(milestone.id);
          batch.update(milestoneRef, {
            numCompleted: milestone.numToComplete,
          });

          batch
            .commit()
            .then(() => {
              this.savingSnackbar = false;
              this.successMessage = "Milestone completed";
              this.successSnackbar = true;
            })
            .catch((error) => {
              this.errorMsg = error.message;
              this.savingSnackbar = false;
              this.errorSnackbar = true;
            });
        } catch (error) {
          this.errorMsg = error.message;
          this.savingSnackbar = false;
          this.errorSnackbar = true;
        }
      }
    },
    async deleteMilestone(milestone) {
      this.savingSnackbar = true;
      try {
        const milestoneRef = db
          .collection("users")
          .doc(this.userId)
          .collection("goals")
          .doc(this.goal.id)
          .collection("milestones")
          .doc(milestone.id);

        await milestoneRef.delete();
        this.milestones = this.milestones.filter((m) => m.id !== milestone.id);

        this.savingSnackbar = false;
        this.successMessage = "Milestone deleted";
        this.successSnackbar = true;
      } catch (error) {
        this.errorMsg = error.message;
        this.savingSnackbar = false;
        this.errorSnackbar = true;
      }
    },
    incrementMilestone(milestone) {
      if (milestone.numCompleted < milestone.numToComplete) {
        milestone.numCompleted++;
        const goalRef = db
          .collection("users")
          .doc(this.userId)
          .collection("goals")
          .doc(this.goal.id);

        const batch = db.batch();
        batch.update(goalRef, {
          numCompleted: this.goal.numCompleted + 1,
        });

        const milestoneRef = goalRef.collection("milestones").doc(milestone.id);
        batch.update(milestoneRef, {
          numCompleted: milestone.numCompleted,
        });

        batch.commit();
      }
    },
    decrementMilestone(milestone) {
      if (milestone.numCompleted > 0) {
        milestone.numCompleted--;
        const goalRef = db
          .collection("users")
          .doc(this.userId)
          .collection("goals")
          .doc(this.goal.id);

        const batch = db.batch();
        batch.update(goalRef, {
          numCompleted: this.goal.numCompleted - 1,
        });

        const milestoneRef = goalRef.collection("milestones").doc(milestone.id);
        batch.update(milestoneRef, {
          numCompleted: milestone.numCompleted,
        });

        batch.commit();
      }
    },
    async editGoal() {
      this.isLoading = true;
      this.editGoalDialog = false;
      try {
        const goalRef = db
          .collection("users")
          .doc(this.userId)
          .collection("goals")
          .doc(this.goal.id);

        goalRef.set(this.goalToEdit);

        this.savingSnackbar = false;
        this.successMessage = "Goal updated";
        this.successSnackbar = true;
      } catch (error) {
        this.errorMsg = error.message;
        this.savingSnackbar = false;
        this.errorSnackbar = true;
      }
      this.isLoading = false;
    },
    async completeGoal() {
      this.savingSnackbar = true;
      try {
        const goalRef = db
          .collection("users")
          .doc(this.userId)
          .collection("goals")
          .doc(this.goal.id);

        await goalRef.update({
          isComplete: true,
        });

        this.savingSnackbar = false;
        this.successMessage = "Goal completed! Congrats!";
        this.successSnackbar = true;
      } catch (error) {
        this.errorMsg = error.message;
        this.savingSnackbar = false;
        this.errorSnackbar = true;
      }
    },
    async uncompleteGoal() {
      this.savingSnackbar = true;
      try {
        const goalRef = db
          .collection("users")
          .doc(this.userId)
          .collection("goals")
          .doc(this.goal.id);

        await goalRef.update({
          isComplete: false,
        });

        this.savingSnackbar = false;
        this.successMessage = "Goal uncompleted";
        this.successSnackbar = true;
      } catch (error) {
        this.errorMsg = error.message;
        this.savingSnackbar = false;
        this.errorSnackbar = true;
      }
    },
    async deleteGoal() {
      this.isLoading = true;
      try {
        db.collection("users")
          .doc(this.userId)
          .collection("goals")
          .doc(this.$route.params.id)
          .delete();
      } catch (error) {
        this.errorMsg = error.message;
        this.errorSnackbar = true;
      }
      this.isLoading = false;
      this.$router.push("/overview");
    },
    resetState() {
      this.newMilestone = {
        name: "",
        milestoneType: "number",
        description: "",
        numCompleted: 0,
        numToComplete: 0,
      };
      this.milestoneDialog = false;
    },

    // Task Methods
    async addTask() {
      try {
        db.collection("users")
          .doc(this.userId)
          .collection("tasks")
          .add({
            name: this.taskName,
            isComplete: false,
            goalId: this.$route.params.id,
            spaceId: this.goal.spaceId,
            date: moment(new Date()).format("YYYY-MM-DD"),
          });
        this.taskName = null;
      } catch (e) {
        alert(e);
      }
    },
    async deleteTask(task) {
      try {
        db.collection("users")
          .doc(this.userId)
          .collection("tasks")
          .doc(task.id)
          .delete();
      } catch (e) {
        alert(e);
      }
    },
    async completeTask(task) {
      try {
        db.collection("users")
          .doc(this.userId)
          .collection("tasks")
          .doc(task.id)
          .update({ isComplete: task.isComplete });
      } catch (e) {
        alert(e);
      }
    },

    // Motivator Methods
    addMotivator() {
      try {
        db.collection("users").doc(this.userId).collection("motivators").add({
          name: this.selectedMotivator,
          goalId: this.$route.params.id,
          spaceId: this.space.id,
        });
      } catch (error) {
        alert(error);
      }
      this.selectedMotivator = "";
    },
    resetAddMotivators() {
      this.editMotivators = false;
      this.selectedMotivator = "";
    },
    deleteMotivator(motivator) {
      try {
        db.collection("users")
          .doc(this.userId)
          .collection("motivators")
          .doc(motivator.id)
          .delete();
      } catch (e) {
        alert(e);
      }
    },
  },
};
</script>
