<template>
  <v-container fill-height fluid style="background-color: #e8eaf6">
    <v-row justify="center">
      <v-col align="center">
        <v-card width="450" flat>
          <v-card-title class="justify-center my-5">
            <a href="/">
              <v-avatar size="40" tile>
                <img src="../assets/apollo-icon-grey.svg" />
              </v-avatar>
            </a>
            <a href="/" style="text-decoration: none; color: grey">
              <span class="ml-2 app-bar-title">APOLLO</span>
            </a>
          </v-card-title>
          <v-card-text class="mx-5 text-left">
            <h2 class="mb-2">Welcome to Apollo!</h2>
            <p class="text-body-2">
              Please sign-in to your account and start the adventure
            </p>
            <v-alert v-if="errorMsg" border="top" color="red" class="mx-5">
              {{ errorMsg }}
            </v-alert>
          </v-card-text>
          <v-card-title class="mx-5">
            <v-btn
              block
              color="primary"
              class="mb-3"
              @click="signInWithGoogle()"
              :loading="isLoading"
            >
              <v-icon left> mdi-google </v-icon>
              Login with Google
            </v-btn>
          </v-card-title>

          <!-- divider -->
          <v-card-text class="d-flex align-center mt-2">
            <v-divider class="ml-5" />
            <span class="mx-5">or</span>
            <v-divider class="mr-5" />
          </v-card-text>

          <!-- social links -->
          <v-card-actions class="d-flex justify-center">
            <v-btn
              v-for="link in socialLink"
              :key="link.icon"
              icon
              class="ms-1"
            >
              <v-icon :color="link.color">
                {{ link.icon }}
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { db } from "../firebase/firebase";

export default {
  data() {
    return {
      errorMsg: "",
      form: false,
      show: false,
      isLoading: false,
      socialLink: [
        {
          icon: "mdi-facebook",
          color: "#4267b2",
          colorInDark: "#4267b2",
        },
        {
          icon: "mdi-twitter",
          color: "#1da1f2",
          colorInDark: "#1da1f2",
        },
        {
          icon: "mdi-github",
          color: "#272727",
          colorInDark: "#fff",
        },
        {
          icon: "mdi-google",
          color: "#db4437",
          colorInDark: "#db4437",
        },
      ],
    };
  },
  methods: {
    async signInWithGoogle() {
      this.isLoading = true;
      try {
        // const provider = new firebase.auth.GoogleAuthProvider();
        //provider.addScope('https://www.googleapis.com/auth/calendar');

        // const loginResult = await firebase.auth().signInWithPopup(provider);

        const { currentUser } = await this.$gapi.login();
        
        const token = currentUser.getAuthResponse().id_token;
        const credential = firebase.auth.GoogleAuthProvider.credential(token);
        const loginResult = await firebase.auth().signInWithCredential(credential);

        const userData = await db
          .collection("users")
          .doc(loginResult.user.uid)
          .get();
        const user = userData.data();

        if (userData.exists) {
          this.$store.dispatch("user/setUserData", {
            id: loginResult.user.uid,
            name: user.name,
            bio: user.bio,
            vision: user.vision,
            email: user.email,
            photoURL: user.photoURL,
          });
        } else {
          await db.collection("users").doc(loginResult.user.uid).set({
            id: loginResult.user.uid,
            photoURL: loginResult.user.photoURL,
            email: loginResult.user.email,
            name: loginResult.user.displayName,
            bio: "Hi, I am new here!",
            vision: "Become the best version of myself",
          });

          this.$store.dispatch("user/setUserData", {
            id: loginResult.user.uid,
            photoURL: loginResult.user.photoURL,
            email: loginResult.user.email,
            name: loginResult.user.displayName,
            bio: "Hi, I am new here!",
            vision: "Become the best version of myself",
          });
        }

        this.$router.push("/overview");
      } catch (error) {
        this.errorMsg = "An unexpected error has occurred!";
        if (error.message) {
          this.errorMsg = error.message;
        }
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.app-bar-title {
  font-size: 30px;
  font-weight: bold;
}
.beta {
  font-weight: bold;
  color: blue;
}
.bg-decoration {
  background-color: #a88cff;
  height: 200px;
  margin: -13px;
}
</style>
