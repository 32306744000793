<template>
  <PageLayout>
    <template v-slot:header>
      <PageHeader title="Settings" :breadcrumbs="breadcrumbs" />
    </template>
    <template v-slot:body>
      <v-row class="mt-0">
        <v-col>
          <v-card tile flat class="rounded-lg">
            <v-card-title>Account</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="px-0 pt-0">
              <v-tabs v-model="tab" color="primary">
                <v-tab href="#profile">Profile</v-tab>
                <v-tab href="#spaces">Spaces</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item value="profile">
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-card flat outlined class="rounded-lg">
                          <v-card-title>Profile Picture</v-card-title>
                          <v-divider></v-divider>
                          <v-card-text class="text-center">
                            <v-avatar size="100" class="mb-4">
                              <v-img :src="userPhoto"></v-img>
                            </v-avatar>
                            <p>Upload/Change Your Profile Image</p>
                            <v-btn
                              color="primary"
                              @click="imageUploadDialog = true"
                              >Upload Avatar</v-btn
                            >
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-card flat outlined class="rounded-lg">
                          <v-btn v-if="screenIsMobile" absolute right small class="mt-3" depressed color="primary" fab @click="setEdit">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn
                            v-else
                            absolute
                            top
                            right
                            color="primary"
                            @click="setEdit"
                          >
                            <v-icon left>mdi-pencil</v-icon>Edit
                          </v-btn>
                          <v-card-title>Account Details</v-card-title>
                          <v-divider></v-divider>
                          <v-card-text>
                            <v-row no-gutters justify="center">
                              <v-col cols="12" class="mt-5">
                                <v-text-field
                                  outlined
                                  label="Display Name"
                                  v-model="displayName"
                                  :disabled="!editMode"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  outlined
                                  label="Email"
                                  v-model="userEmail"
                                  disabled
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              v-if="editMode"
                              @click="resetFields()"
                              color="error"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              v-if="editMode"
                              @click="updateName()"
                              color="primary"
                              class="mr-5"
                            >
                              Save Changes
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
                <v-tab-item value="spaces">
                  <v-container fluid>
                    <v-row no-gutters>
                      <v-col>
                        <v-card flat outlined class="rounded-lg">
                          <v-card-title>Spaces</v-card-title>
                          <v-divider />
                          <v-card-text>
                            <v-list
                              two-line
                              v-if="$store.state.space.spaces.length > 0"
                            >
                              <template
                                v-for="(space, index) in $store.state.space
                                  .spaces"
                              >
                                <v-divider
                                  v-if="index !== 0"
                                  :key="`divider-${index}`"
                                />
                                <v-list-item :key="`list-item-${index}`">
                                  <v-list-item-avatar
                                    :color="space.color"
                                    tile
                                    class="rounded-lg"
                                  >
                                    <v-icon dark>{{ space.icon }}</v-icon>
                                  </v-list-item-avatar>
                                  <v-list-item-content>
                                    <v-list-item-title class="text-h6">{{
                                      space.name
                                    }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                      {{ space.description }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-menu offset-y>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn icon v-bind="attrs" v-on="on">
                                          <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                      </template>
                                      <v-list>
                                        <v-list-item @click="setDelete(index)">
                                          <v-list-item-title
                                            >Delete</v-list-item-title
                                          >
                                          <v-list-item-action>
                                            <v-icon color="error"
                                              >mdi-trash-can</v-icon
                                            >
                                          </v-list-item-action>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>
                                  </v-list-item-action>
                                </v-list-item>
                              </template>
                            </v-list>
                            <p v-else>
                              You have no spaces. Click 'New Space' to create
                              one!
                            </p>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Dialogs -->
      <v-dialog v-model="confirmDeleteDialog" max-width="600">
        <v-card>
          <v-card-title class="text-h4">
            <v-avatar :color="selectedSpace.color" tile class="rounded-lg mr-3">
              <v-icon dark>{{ selectedSpace.icon }}</v-icon>
            </v-avatar>
            {{ selectedSpace.name }}
          </v-card-title>
          <v-card-text>
            <p class="text-body-1">
              Are you sure you want to delete this space?
            </p>
            <v-alert type="warning" dense border="left">
              Deleting this space with
              <strong>remove all goals, events, and tasks</strong>
              associated with it as well!
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetDelete()">Cancel</v-btn>
            <v-btn color="success" depressed @click="deleteSpace()"
              >Confirm</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="imageUploadDialog" max-width="500">
        <v-card :loading="isLoading">
          <template slot="progress">
            <v-progress-linear
              color="primary"
              height="5"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title>New Profile Picture</v-card-title>
          <v-card-text>
            <v-file-input
              label="Upload a new image"
              v-model="selectedImage"
            ></v-file-input>
            <v-img v-if="selectedImage" :src="previewImage"></v-img>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="error" @click="resetImage()">Cancel</v-btn>
            <v-btn
              depressed
              color="primary"
              :loading="isLoading"
              @click="updateImage()"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </PageLayout>
</template>

<script>
import PageLayout from "../layouts/PageLayout.vue";
import { db, storage } from "../firebase/firebase";
import { mapState } from "vuex";
import PageHeader from "../components/PageHeader.vue";

export default {
  name: "Settings",
  components: {
    PageLayout,
    PageHeader,
  },
  data() {
    return {
      tab: null,
      profileImage: null,
      imageUploadDialog: false,
      confirmDeleteDialog: false,
      errorMsg: "",
      selectedSpace: {},
      selectedImage: null,
      isLoading: false,
      editMode: false,
      displayName: "",
      email: "",
      breadcrumbs: [
        {
          icon: "mdi-home",
          text: "",
          href: "/",
        },
        {
          text: "Settings",
        },
      ],
    };
  },
  mounted() {
    this.displayName = this.username;
  },
  computed: {
    screenIsMobile() {
      return this.$vuetify.breakpoint.width < 960;
    },
    previewImage() {
      return URL.createObjectURL(this.selectedImage);
    },
    ...mapState({
      username: (state) => state.user.name,
      userId: (state) => state.user.id,
      userPhoto: (state) => state.user.photoURL,
      userEmail: (state) => state.user.email,
    }),
  },
  methods: {
    setEdit() {
      this.displayName = this.username;
      this.editMode = true;
    },
    resetDelete() {
      this.selectedSpace = {};
      this.confirmDeleteDialog = false;
    },
    setDelete(i) {
      this.selectedIndex = -1;
      this.selectedSpace = this.$store.state.space.spaces[i];
      this.confirmDeleteDialog = true;
    },
    resetFields() {
      this.displayName = this.username;
      this.editMode = false;
    },
    async updateName() {
      db.collection("users").doc(this.userId).update({
        name: this.displayName,
      });
      await this.$store.dispatch("user/setUserName", this.displayName);
      this.resetFields();
    },
    async updateImage() {
      this.isLoading = true;
      try {
        storage
          .ref()
          .child(`profileImages/${this.userId}`)
          .put(this.selectedImage)
          .then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
              db.collection("users").doc(this.userId).update({ photoURL: url });
              this.$store.dispatch("user/setUserPhoto", url);
            });
            this.resetImage();
          });
      } catch (error) {
        this.errorMsg = "An unexpected error has occurred!";
        if (error.message) {
          this.errorMsg = error.message;
        }
      }
      this.isLoading = false;
    },
    resetImage() {
      this.imageUploadDialog = false;
      this.selectedImage = null;
    },
    async deleteSpace() {
      this.isLoading = true;
      try {
        db.collection("users")
          .doc(this.userId)
          .collection("spaces")
          .doc(this.selectedSpace.id)
          .delete();

        this.confirmDeleteDialog = false;
      } catch (error) {
        alert(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
