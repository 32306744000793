<template>
  <div>
    <v-navigation-drawer clipped app flat v-model="drawerState">
      <v-container>
        <v-card flat class="ma-2 rounded-lg" to="/settings">
          <v-list-item two-line style="background-color: #f5f5f5">
            <v-list-item-avatar>
              <v-img :src="userPhoto"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-2">{{
                username
              }}</v-list-item-title>
              <v-list-item-subtitle>user</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>

        <v-list dense nav>
          <v-subheader class="text-uppercase font-weight-bold text--primary"
            >Dashboard</v-subheader
          >
          <v-list-item-group color="primary">
            <v-list-item link to="/overview">
              <v-list-item-icon>
                <v-icon>mdi-home-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Overview</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="'/my-spaces'">
              <v-list-item-icon>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>My Spaces</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider class="my-5" />
            <v-subheader class="text-uppercase font-weight-bold text--primary"
              >Applications</v-subheader
            >
            <v-list-item link to="/tasks">
              <v-list-item-icon>
                <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Tasks</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="'/calendar'">
              <v-list-item-icon>
                <v-icon>mdi-calendar-blank</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Calendar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="my-5" />
          </v-list-item-group>
        </v-list>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    drawerState: {
      get() {
        return this.$store.getters.drawerState;
      },
      set(value) {
        return this.$store.commit("TOGGLE_DRAWER_STATE", value);
      },
    },
    ...mapState({
      userId: (state) => state.user.id,
      username: (state) => state.user.name,
      userEmail: (state) => state.user.email,
      userPhoto: (state) => state.user.photoURL,
    }),
  },
  methods: {},
};
</script>
