<template>
  <v-container>
    <v-toolbar flat color="transparent">
      <a href="/">
        <v-avatar size="40" tile>
          <img src="../assets/apollo-icon.svg" />
        </v-avatar>
      </a>
      <a href="/" style="text-decoration: none; color: black">
        <span class="ml-2 app-bar-title">APOLLO</span>
        <small class="beta">BETA</small>
      </a>
      <v-spacer />
      <v-menu
        v-if="userId != null"
        nudge-bottom="50"
        nudge-left="50"
        rounded="lg"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge bottom color="success" dot offset-x="10" offset-y="10">
            <v-avatar size="40" v-bind="attrs" v-on="on">
              <v-img :src="userPhoto"></v-img>
            </v-avatar>
          </v-badge>
        </template>
        <v-card>
          <v-card-title class="text-subtitle-2">{{ username }}</v-card-title>
          <v-card-subtitle>{{ userEmail }}</v-card-subtitle>
          <v-divider></v-divider>
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item to="/overview">
                <v-list-item-content>
                  <v-list-item-title>Overview</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/settings">
                <v-list-item-content>
                  <v-list-item-title>Account Settings</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/about">
                <v-list-item-content>
                  <v-list-item-title>About Apollo</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="logoutUser()">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
      <v-btn v-else to="/sign-in" rounded color="primary">
        <v-icon>mdi-rocket-launch-outline</v-icon>
        Login
      </v-btn>
    </v-toolbar>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { firebaseAuth } from "../firebase/firebase";

export default {
  name: "HomeNavBar",
  methods: {
    async logoutUser() {
      try {
        firebaseAuth.signOut();
        this.$store.dispatch("user/clearUserData");
        this.$router.push("/");
      } catch (e) {
        alert(e);
      }
    },
  },
  computed: mapState({
    userId: (state) => state.user.id,
    username: (state) => state.user.name,
    userEmail: (state) => state.user.email,
    userPhoto: (state) => state.user.photoURL,
  }),
};
</script>

<style lang="scss" scoped>
.app-bar-title {
  font-size: 30px;
  font-weight: bold;
}
.beta {
  font-weight: bold;
  color: blue;
}
</style>
