<template>
  <v-row>
    <v-col>
      <v-toolbar flat class="rounded-lg">
        <v-toolbar-title class="text-h5 font-weight-bold" style="max-width: 270px;">
          {{ title ? title : "Page Title" }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" divider="•">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :href="item.href" v-if="item.icon">
              <v-icon small color="primary">mdi-home</v-icon>
            </v-breadcrumbs-item>
            <v-breadcrumbs-item :href="item.href" v-else>
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-toolbar>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    title: String,
    breadcrumbs: Array,
  },
};
</script>
