import { firestoreAction } from "vuexfire";
import { db } from "../../firebase/firebase";

const namespaced = true;

const state = {
  tasks: [],
};

const actions = {
  bindTasksRef: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "tasks",
      db.collection("users").doc(context.rootState.user.id).collection("tasks")
    );
  }),
  unbindTasksRef: firestoreAction((context) => {
    return context.unbindFirestoreRef("tasks");
  }),
};

const getters = {
  tasksWithColor(state, getters, rootState) {
    let tasks = state.tasks;
    
    for (let i = 0; i < tasks.length; i++) {
      let space = rootState.space.spaces.find(space => space.id === tasks[i].spaceId);
      if (space) {
        tasks[i].color = space.color;
      } else {
        tasks[i].color = "grey";
      }
    }

    return tasks;
  },
  tasksBySpace: (state) => (id) => {
    return state.tasks.filter(task => task.spaceId === id);
  },
  tasksByGoal: (state) => (id) => {
    return state.tasks.filter(task => task.goalId === id);
  },
  numTasksBySpace: (state) => (id) => {
    return state.tasks.filter(task => task.spaceId === id).length;
  }
}

export default {
  namespaced,
  state,
  actions,
  getters
};
