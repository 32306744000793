import { db } from "../../firebase/firebase";

const namespaced = true;

const state = {
    events: [],
};

const mutations = {
    SET_EVENTS(state, payload) {
        state.events = payload;
    },
    ADD_EVENT(state, payload) {
        state.events.push(payload);
    },
    SET_EVENT(state, payload) {
        const event = state.events.find((event) => event.id === payload.id);
        Object.assign(event, payload);
    },
    DELETE_EVENT(state, id) {
        const index = state.events.findIndex((event) => event.id === id);
        state.events.splice(index, 1);
    },
    ADD_EVENT_BY_CLICK(state, payload) {
        const index = state.events.length - 1;
        const event = state.events[index];
        Object.assign(event, payload);
    },
};

const actions = {
    async getEvents({ commit }, userId) {
        try {
            const snapshot = await db
                .collection("users")
                .doc(userId)
                .collection("events").orderBy("start").get();
            const events = snapshot.docs.map((doc) => {
                let event = doc.data();
                event.id = doc.id;
                return event;
            });
            commit("SET_EVENTS", events);
        } catch (e) {
            alert(e);
        }
    },
    addEvent({ commit }, event) {
        commit("ADD_EVENT", event);
    },
    deleteEvent({ commit }, id) {
        commit("DELETE_EVENT", id);
    },
    editEvent({ commit }, event) {
        commit("SET_EVENT", event);
    },
    addEventByClick({ commit }, event) {
        commit("ADD_EVENT_BY_CLICK", event);
    },
};

export default {
    namespaced,
    state,
    mutations,
    actions,
};